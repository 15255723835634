import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';

function ContactFooter() {
  const [siteInfo, setSiteInfo] = useState({});
  useEffect(() => {
    const fetchInfo = async () => {
      try {
        let info = await axios.post(
          'https://bayla-be-main.vercel.app/siteInfo'
        );
        info = info?.data;
        if (info?.code === 1000) {
          setSiteInfo({
            facebook: info?.data?.facebook,
            tiktok: info?.data?.tiktok,
            phoneNumber: info?.data?.phoneNumber,
            zalo: info?.data?.zalo,
            images: info?.data?.images,
          });
        }
      } catch (error) {
        console.log(
          `ERROR when call get list homestay ${
            error.message
          } -- ${JSON.stringify(error)}`
        );
        setSiteInfo({
          facebook: 'Link facebook',
          tiktok: 'Link tiktok',
          phoneNumber: 'Số điện thoại',
          zalo: 'Số zalo',
          images: [],
        });
      }
    };
    fetchInfo();
  }, []);

  return (
    <Fragment>
                {/* <div className='contact-header'>Liên hệ</div> */}
      <div className='contact-card-container' style={{ marginBottom: '40px' }}>
      {/* <div class="contact-button" style={{  borderRadius: '50px', marginBottom: '17px', background: 'linear-gradient(to right, #C1B7FB, #E9E9FC)' }}>Liên Hệ</div> */}
      <a href='' style={{ textDecoration: 'none' }}>
                  <button
                    style={{
                      // background: '#B1A9F8', // Màu xanh dương
                      border: 'none',
                      padding: '5px 90px',
                      borderRadius: '50px', // Bo tròn nút
                      color: '#141414',
                      background: 'linear-gradient(to top right, #ffdab6, #fff)',
                      fontFamily: 'Gilroy-Regular',
                      fontWeight: 'bold',
                      fontSize: '18px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      width: '300px'
                    }}
                  >
                    Liên Hệ
                  </button>
                </a>
        {/* Khối trên cùng */}
        <div className='contact-card'>
          {/* <div className='contact-header'>Liên hệ</div> */}
          <div className='contact-item-list'>
            <div className='contact-item'>
              <div className='icon-circle'></div>
              <a href={siteInfo.facebook} style={{ color: 'inherit' }}>
                Facebook -- Bayla Home
              </a>
            </div>
            <div className='contact-item'>
              <div className='icon-circle'></div>
              <a
                href={`https://zalo.me/${siteInfo.zalo}`}
                style={{ color: 'inherit' }}
              >
                Zalo -- Bayla Home
              </a>
            </div>
            <div className='contact-item'>
              <div className='icon-circle'></div>
              <a href={siteInfo.tiktok} style={{ color: 'inherit' }}>
                Tiktok -- Bayla Home
              </a>
            </div>
            <div className='contact-item'>
              <div className='icon-circle'></div>
              <a
                href={`tel:${siteInfo.phoneNumber}`}
                style={{ color: 'inherit' }}
              >
                Hotline -- Bayla Home 24/24
              </a>
            </div>
            <div className='contact-item'>
              <div className='icon-circle'></div>
              <a
                href='https://maps.app.goo.gl/nMsFHUS9vxUUAwjb6'
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                Nguyễn Văn Linh, Thanh Khê, Đà Nẵng
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ContactFooter;

import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Menu from './menu';
import numeral from 'numeral';

import moment from 'moment-timezone';
import momentAdd from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import _ from 'lodash';
import axios from 'axios';

import InfiniteScrollTable from './tableReact';
import Compressor from 'compressorjs';

import FixedBottomBar from './fixBottomBar';

function BookingDetail() {
  const [homeStay, setHomeStay] = useState({});
  const [intervalQueryId, setIntervalQueryId] = useState(null);
  const [totalPayment, setTotalPayment] = useState(0);
  const [isLoadingTotalPayment, setIsLoadingTotalPayment] = useState(false);
  const [bookingTimes, setBookingTimes] = useState([]);

  const [bookingTime, setBookingTime] = useState([]);

  const [imagesCCCD, setImagesCCCD] = useState([]);
  const [isStatusScroll, setIsStatusScroll] = useState(true);
  const sectionRefDetail = useRef(null);
  const sectionRefTable = useRef(null);
  const navigate = useNavigate();


  const handleResize = () => {
    return window.innerWidth <= 768;
  };

  let now = new Date();
  let futureDate = new Date();
  const fetchBookingTimeSlot = async (roomId) => {
    futureDate.setDate(now.getDate() + 30);
    try {
      let bookingTime = await axios.get(
        `https://bayla-be-parent.vercel.app/api/get-booking?dateFrom=${now}&dateTo=${futureDate}&roomId=${roomId}`,
        {},
        { timeout: 60000 }
      );
      setBookingTimes(bookingTime.data[0].dateAvailable[0]);
    } catch (error) {
      console.log(
        `ERROR when call get list homestay ${error.message} -- ${JSON.stringify(
          error
        )}`
      );
    }
  };


  const [numPeople, setNumPeople] = useState(2);

  const [showInputCCCD, setShowInputCCCD] = useState(false);

  const checkShowInputCCCD = (data) => {
    let isShow = false;
    if (!_.isEmpty(data)) {
      const overNightSlot = _.find(data?.bookingSlots || [], { order: 4 });
      if (overNightSlot) isShow = true;
    }

    setShowInputCCCD(isShow);

    return isShow;
  };

  const [bookDateData, setBookDateData] = useState([]);
  const dataChooseDate = (data) => {
    console.log(`dataChooseDate ${JSON.stringify(data)}`);
    setIsLoadingTotalPayment(false);
    setBookDateData(data);
    // checkShowInputCCCD(data);
  };
  useEffect(() => {
    calTotalPayment();
  }, [bookDateData]);

  const [errorBookDateData, setErrorBookDateData] = useState({
    isValid: true,
    message: '',
  });
  const checkBookDateDate = () => {
    let orderList = _.map(bookDateData?.bookingSlots || [], 'order');
    orderList = _.sortBy(orderList);

    if (_.isEqual(orderList, [1, 3])) {
      setErrorBookDateData({
        isValid: false,
        message: 'Các khung giờ đặt phải liên tiếp nhau',
      });
      return false;
    }

    return true;
  };

  const returnDateBookData = (item) => {
    return moment(item.date, 'DD-MM-YYYY').toDate();
  };
  const calTotalPayment = async () => {
    try {
      setIsLoadingTotalPayment(true);

      if (_.isEmpty(bookDateData)) {
        setTotalPayment(0);
        setIsLoadingTotalPayment(false);
        return;
      }
      let bookingList = _.sortBy(bookDateData, returnDateBookData);
      bookingList = _.map(bookingList, (item) => {
        const result = {
          date: item.date,
        };
        let bookingSlots = _.sortBy(item.bookingSlots, ['order']);
        const slots = _.map(bookingSlots, (slot) => {
          return slot.order - 1;
        });
        result.slots = slots;
        return result;
      });

      const data = {
        roomId: homeStay.id,
        totalCustomer: numPeople,
        bookingList,
        isCalAmountOnly: true,
      };

      let response = await axios.post(
        'https://bayla-be-main.vercel.app/booking/v2',
        data
      );
      console.log(`response ${JSON.stringify(response)}`);
      response = response?.data;
      if (response?.code === 1000) {
        setTotalPayment(response?.data?.total);
        console.log(`totalPayment ${response?.data?.total}`);

      }
      setIsLoadingTotalPayment(false);
    } catch (error) {
      setTotalPayment(0);
      setIsLoadingTotalPayment(false);
    }

  };

  useEffect(() => {
    console.log(`totalPayment ${totalPayment}`);
  }, [totalPayment]);


  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const handleBack = () => { };

  const handleNext = (type) => {
    navigate('/fill-information', {
      state: {
        data: { homeStay: homeStay, bookingTIme: bookDateData },
      },
    })
  };

  // Trong component của bạn
  let query = useQuery();
  let id = query.get('id'); // "101"

  const location = useLocation();
  const receivedData = location.state ? location.state.data : null;
  // setHomeStay(receivedData?.data || {});

  useEffect(() => {

    if (receivedData?.bookingTIme != null) {

      setBookingTime(receivedData?.bookingTIme || []);

    }
    fetchBookingTimeSlot(id);
    setHomeStay(receivedData?.homeStay || {});



    const fetchHomeStay = async () => {
      try {
        let home = await axios.get(
          `https://bayla-be-parent.vercel.app/api/get-room/${id}`,
          {},
          { timeout: 60000 }
        );
        console.log('home', home.data);
        setHomeStay(home.data[0]);
        console.log('homeStay', homeStay);
      } catch (error) {
        console.log(
          `ERROR when call get list homestay ${error.message
          } -- ${JSON.stringify(error)}`
        );
      }
    };
    if (_.isEmpty(receivedData)) {
      fetchHomeStay();
    }

    window.scrollTo(0, 0);

    setBookDateData(receivedData?.bookingTIme || []);

    checkShowInputCCCD(receivedData?.bookingTIme || {});

    return () => {
      if (intervalQueryId) {
        clearInterval(intervalQueryId);
      }
    };
  }, []);

  return (
    <Fragment>
      <header className='header-section'>
        <div className='top-nav'>
          <div className='container'></div>
        </div>
        <div className='menu-item'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4'>
                <div className={handleResize() == true ? 'fixed-logo' : 'logo'}>
                  <a href='/'>
                    <img
                      style={{
                        // marginTop: '10px',
                        maxWidth: '70%',
                      }}
                      src='assets/img/logo/logo-xanh.png'
                      alt=''
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className='spacer-height' />
      <section className='room-details-section spad'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8'>
              <Link to= {`/room-detail?id=${homeStay?.id}`}>
              <div
                className='container'
                style={{
                  border: '2px solid #fe9574',
                  width: '100%',

                  borderRadius: '30px',
                  marginBottom: '10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '10px',

                }}
              >
                <div style={{ width: '50%' }}>
                  <img
                    className='d-block w-100'
                    src={homeStay.images?.[0]}
                    alt=''
                    style={{
                      // objectFit: 'cover',
                      height: '200px',
                      width: '100%',
                      borderRadius: '10px',
                      textAlign: 'center'
                    }}
                  />
                </div>
                <div
                  style={{
                    width: '49%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                  }}
                >
                  <div className='ri-text' style={{ marginTop: '20px' }}>
                    <p
                      style={{
                        textAlign: 'left',
                        margin: '0',
                        padding: '0',
                        fontSize: '15px',
                        fontWeight: 'bold',
                      }}
                    >
                        <Link to={`/room-detail?id=${homeStay?.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
    {homeStay?.name}
  </Link>
                    </p>
                    <p
                      style={{
                        textAlign: 'left',
                        fontSize: '13px',
                        margin: '0',
                        padding: '0',
                      }}
                    >
                      {numeral(homeStay?.priceList?.overNight || 0).format(
                        '0,0'
                      )}{' '}
                      VND/ Qua đêm
                    </p>
                    <p
                      style={{
                        textAlign: 'left',
                        fontSize: '13px',
                        margin: '0',
                        padding: '0',
                      }}
                    >
                      {numeral(homeStay?.priceList?.threeHours || 0).format(
                        '0,0'
                      )}{' '}
                      VND/ 3h
                    </p>
                  </div>
                </div>
              </div>
              </Link>
              <div ref={sectionRefTable} className='target-section' />
              {/* <div className='rd-date-available' id='table-list-available'>
                {homeStay?.id && (
                  <InfiniteScrollTable
                    data={{
                      roomId: homeStay?.id,
                      bookingTimeSlots: bookingTimes.bookingTimeSlots,
                      bookDateData: bookDateData,
                      bookingTime: bookingTime,
                    }}
                    chooseDate={dataChooseDate}
                  />
                )}
              </div> */}
              <div className="rd-date-available" id="table-list-available">
                {homeStay?.id && <InfiniteScrollTable data={{ homeStay: homeStay, roomId: homeStay?.id, bookingTimeSlots: homeStay?.bookingTimeSlots, bookDateData: bookDateData }} chooseDate={dataChooseDate} />}
              </div>
              <div
                style={{
                  backgroundColor: 'white',
                  minHeight: '50px',
                  padding: '5px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    marginTop: '15px',
                    fontSize: '12px',
                  }}
                >
                  <div
                    style={{
                      margin: '0 10px 0 0',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: '#fba567',
                        width: '50px',
                        height: '20px',
                        margin: '0 2px 0 0',
                        display: 'flex',
                        borderRadius: '7px',
                      }}
                    ></div>{' '}
                    <span>Đã Đặt</span>
                  </div>
                  <div
                    style={{
                      margin: '0 10px 0 0',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: '#white',
                        width: '50px',
                        height: '20px',
                        margin: '0 2px 0 0',
                        background: '#fff6ee',
                        borderRadius: '7px',
                      }}
                    ></div>{' '}
                    <span>Còn Trống</span>
                  </div>
                  <div
                    style={{
                      margin: '0 10px 0 0',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: '#fdc9a3',
                        width: '50px',
                        height: '20px',
                        margin: '0 2px 0 0',
                        borderRadius: '7px',
                      }}
                    ></div>{' '}
                    <span>Đang chọn</span>
                  </div>
                </div>
              </div>
            </div>
            <div ref={sectionRefDetail} className='target-section' />

          </div>
        </div>
      </section>

      <FixedBottomBar
        totalAmount={numeral(totalPayment).format('0,0')}
        onBack={handleBack}
        isLoading={isLoadingTotalPayment}
        onNext={() => {
          if (isStatusScroll === false) {
            setIsStatusScroll(true);
            handleNext('detail');
          } else {
            handleNext();
          }
        }}
        nameButtonNext='Tiếp tục'
      />
      {/* <!-- Footer Section Begin -->
      <Footer /> */}
      {/* <!-- Footer Section End --> */}
    </Fragment>
  );
}

export default BookingDetail;

import { useState, useEffect, Fragment } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Row, Col, ListGroup } from 'react-bootstrap';
import numeral from 'numeral';
import QRCode from 'qrcode.react';
import FixedBottomBar from './pages/fixBottomBar';
import _ from 'lodash';
import axios from 'axios';
import c from "clipboard-js";


function QrCodePage() {
  const [homeStay, setHomeStay] = useState({});
  const [bookingResponse, setBookingResponse] = useState({});
  const [timeCountDownQrCode, setTimeCountDownQrCode] = useState(10 * 60);

  const location = useLocation();
  const [show, setShow] = useState(false);

  const receivedData = location.state ? location.state.data : null;
  const [queryBookingSuccess, setQueryBookingSuccess] = useState(false);

  const timeoutQrCode = () => {
    setShow(false);
  }

  const [isIntervalRunning, setIsIntervalRunning] = useState(false);



  const intervalQueryBooking = async (bookingId) => {
    if (isIntervalRunning) {

      return;
    }
    setIsIntervalRunning(true);
    try {
      const id = setInterval(async () => {
        const result = await queryBooking(bookingId);
        if (result === true) {
          clearInterval(id);
          setIntervalQueryId(null);
          setIsIntervalRunning(false);
        }
      }, 10000);
      setIntervalQueryId(id);
    } catch (error) {
      console.log(`ERROR when  call interval  ${error.message} -- ${JSON.stringify(error)}`);
      setIsIntervalRunning(false);
    }
  }

  const queryBooking = async (bookingId) => {
    try {
      let response = await axios.post('https://bayla-be-main.vercel.app/booking/query', { bookingId });
      response = response?.data
      if (response?.code === 1000) {
        response = response?.data
        if (response?.status === 'PAID') {
          setQueryBookingSuccess(true);
          console.log(`PaID hay chua ${response?.status}`);
          return true;
        }
      }
      console.log(response?.status);
    } catch (error) {
      console.log(`ERROR when call /query/booking  ${error.message} -- ${JSON.stringify(error)}`);
    }
    return false;
  }

  const deleteBooking = async (bookingId) => {
    try {
      let response = await axios.post('https://bayla-be-main.vercel.app/booking/delete-client', { bookingId });
      response = response?.data
      if (response?.code === 1000) {

        console.log(`Da xoa booking ${response?.status}`);
        setQueryBookingSuccess(false);
      }
      console.log(response?.status);
    } catch (error) {
      console.log(`ERROR when call /delete/booking  ${error.message} -- ${JSON.stringify(error)}`);
    }
  };

  const handleBack = () => {

    deleteBooking(bookingResponse?.bookingId);

  };


  const handleResize = () => {
    return window.innerWidth <= 768;
  };

  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    phone: '',
    email: '',
    cccd: '',
    note: ''
  });
  const [intervalQueryId, setIntervalQueryId] = useState(null);

  useEffect(() => {
    setHomeStay(receivedData.homeStay);
    setBookingResponse(receivedData.bookingResponse);
    intervalQueryBooking(receivedData.bookingResponse?.bookingId);
    setCustomerInfo(receivedData.customerInfo);
    return () => {
      if (intervalQueryId) {
        clearInterval(intervalQueryId);
      }
    };
  }, []);

  const navigate = useNavigate();

  const handleNext = (e) => {
    console.log('Next button clicked', customerInfo);
    navigate('/booking-success', { state: { data: { customerInfo: customerInfo } } });

  };

  useEffect(() => {
    if (queryBookingSuccess) {
      handleNext();
    }
  }, [queryBookingSuccess]);

  return (
    <Fragment>
      <header className='header-section'>
        <div className='top-nav'>
          <div className='container'></div>
        </div>
        <div className='menu-item'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4'>
                <div className={handleResize() == true ? 'fixed-logo' : 'logo'}>
                  <a href='/'>
                    <img
                      style={{
                        // marginTop: '10px',
                        maxWidth: '70%',
                      }}
                      src='assets/img/logo/logo-xanh.png'
                      alt=''
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className='spacer-height' style={{ marginTop: '-40px', padding: 'unset' }} />
      <section className='room-details-section spad'>
        <div className='container'>
          <div className='row' >
            <div className='col-lg-4'>
              <div className='room-booking'>
                <div className='container'>
                  <div className='address' style={{ color: '#111111' }}>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <div className='breadcrumb-text'>
                          <h5
                            style={{
                              fontWeight: 'bold',
                              color: '#111111',
                            }}
                          >
                            Thanh toán
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='container'
                  style={{
                    border: '2px solid #fe9574',
                    width: '100%',
                    borderRadius: '30px',
                    marginBottom: '10px',
                    display: 'flex',
                    justifyContent: 'center', // Căn giữa nội dung
                    alignItems: 'center', // Căn giữa theo chiều dọc
                    padding: '10px',
                  }}
                >
                  <QRCode
                    value={bookingResponse?.qrContent}
                    size={256}
                    bgColor="transparent"
                    fgColor="#000000"
                    level="Q"
                    includeMargin={true}
                  />
                </div>
                {/* <div
                className='container'
                style={{
                  border: '2px solid #fe9574',
                  width: '100%',
                 
                  borderRadius: '30px',
                  marginBottom: '10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '10px',
  
                }}
              >
                  <QRCode
                                                        value={bookingResponse?.qrContent}
                                                        size={256}
                                                        bgColor="transparent"
                                                        fgColor="#000000"
                                                        level="Q"
                                                        includeMargin={true}
                                                    />
                <div
                  style={{
                    width: '49%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                  }}
                >
                
                </div>
              </div> */}
                <div className="text-center" style={{ fontFamily: 'Cabin' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>

                    <span style={{ fontSize: '11px', color: '#836b6b', textAlign: 'center' }}>
                      Mã thanh toán chỉ có hiệu lực trong 10 phút và sử dụng cho thanh toán lần này, vui lòng không sao lưu sử dụng cho những lần thanh toán tiếp theo.
                    </span>
                  </div>

                </div>

                < Row >
                  <Col lg={6}>
                    <ListGroup style={{ fontFamily: 'Cabin', borderColor: '#fe9574', borderRadius: '40px', paddingTop: '5px' }}>
                      <ListGroup.Item>
                        <strong>Tên khách hàng:</strong> {customerInfo.name}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Số điện thoại:</strong> {customerInfo.phone}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Email:</strong> {customerInfo.email}
                      </ListGroup.Item>
                      {_.isEmpty(homeStay?.branchName) ? (<Fragment></Fragment>) : (<ListGroup.Item>
                        <strong>Chi nhánh:</strong> {homeStay.branchName}
                      </ListGroup.Item>)}

                      <ListGroup.Item>
                        <strong>Tên phòng:</strong> {homeStay.name}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Thời gian nhận:</strong> {bookingResponse?.from}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Thời gian trả:</strong> {bookingResponse?.to}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Số tiền:</strong> {numeral(bookingResponse?.amount).format('0,0')} vnđ
                      </ListGroup.Item>
                    </ListGroup>
                  </Col>
                  <Col className="border-left">

                  </Col>
                </Row>

              </div>
            </div>
          </div>
        </div>
      </section>
      <FixedBottomBar
        totalAmount={bookingResponse.amount}
        onBack={handleBack}

      />

    </Fragment>

  );
}

export default QrCodePage;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import numeral from 'numeral';

const FixedBottomBar = ({
  totalAmount,
  onBack,
  onNext,
  nameButtonNext,
  isLoading,
  isLoadingBooking,
}) => {
  const navigate = useNavigate();
  const [totalPrice, setTotalPrice] = useState();
  const [isLoadingChooseRoom, setIsLoading] = useState(false);
  const [isLoadingWhenBooking, setIsLoadingBooking] = useState(false);
  useEffect(() => {
    // Update totalPrice when totalAmount changes
    if (!(totalAmount === 0)) {
      setTotalPrice(totalAmount);
    }else{
      setTotalPrice(null);
    }
  }, [totalAmount]);

  useEffect(() => {
    setIsLoadingBooking(isLoadingBooking);
    console.log('isLoadingBooking in bottom', isLoadingBooking);
  }, [isLoadingBooking]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  const goBack = () => {
    if (typeof onBack === 'function') {
      onBack();
    }
    navigate(-1); // Số -1 cho biết quay lại trang trước
  };
  return (
    <div className='fixed-bottom-bar'>
      <div className='fixed-bottom-bar-content'>
        {onBack == null  || isLoadingBooking ? (
          <div style={{width:"30%"}}></div>
        ) : (
          <button className='button button-back' onClick={goBack}>
            <img
              src='https://img.icons8.com/?size=100&id=99996&format=png&color=FFA500'
              alt='icon'
              style={{ width: '20px', height: '20px',marginRight: '4px' }}
            />{' '}
            Quay lại
          </button>
        ) }
         {isLoading ? (
          <Oval
            height={40}
            width={40}
            color='#fe9574'
            wrapperStyle={{}}
            wrapperClass=''
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor='#fe9574'
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        ) : totalPrice != 0 && (
          totalPrice && (
            <span style={{ color: '#212529', fontWeight: 'bold',}}>
              {numeral(totalPrice).format('0,0')} vnđ
            </span>
          )
        )}
        {onNext == null || isLoadingBooking ? (
          <div style={{ width: '30%' }}></div>
        ) : (
          <button className='button button-next' onClick={(e) => onNext(e)}>
            {nameButtonNext}
            <img
              src='https://img.icons8.com/?size=100&id=99982&format=png&color=FFA500'
              alt='icon'
              style={{
                left: '5px',
                width: '20px',
                height: '20px',
                marginLeft: '4px' 
              }}
            />
          </button>
        ) }
      </div>
    </div>
  );
};

export default FixedBottomBar;

import React, { useEffect, useState } from "react";

const TermsAndConditions = ({ homeStay, sendStateChecked }) => {
  const [checkedTerm, setCheckedTerm] = useState(false);
  const onChangeState = () => {
    setCheckedTerm((prev) => !prev);
  };

  useEffect(() => {
    sendStateChecked(checkedTerm);
  }, [checkedTerm]);
  return (
    <div style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
      <input
        type="checkbox"
        checked={checkedTerm}
        id="terms"
        name="terms"
        style={{
          marginLeft: "5px",
          marginRight: "8px",
          marginBottom: "10px",
          borderRadius: "20px",
        }}
        onChange={onChangeState}
      />
      <div className="checked">
        <label htmlFor="terms">
          Khách đã đọc và đồng ý với{" "}
          <a
            href={homeStay?.rule}
            target="_blank"
            style={{ fontSize: "12px", color: "#f7d672" }}
            rel="noreferrer"
          >
            <span style={{ textDecoration: "underline" }}>Nội quy</span>
          </a>{" "}
          &{" "}
          <a
            href="https://drive.google.com/drive/folders/1-2YvRoxRhcOp6923B0VJgnjiUuh-Twt5"
            target="_blank"
            style={{ fontSize: "12px" }}
            rel="noreferrer"
          >
            <span style={{ textDecoration: "underline" }}>Chính sách</span>
          </a>
        </label>
      </div>
    </div>
  );
};

export default TermsAndConditions;

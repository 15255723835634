import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "../../selectInput.css";
import classNames from "classnames";

const SelectInput = ({
  options,
  defaultOption,
  sendValueToParent = () => {},
  width,
  widthDropdow,
  placeholder,
  roomName,
}) => {
  const widthSelect = width || "170px";
  const widthSelectDropdow = widthDropdow || "auto";
  const [optionsValue, setOptionsValue] = useState([]);
  const inputRef = useRef(null);
  const [indexSelect, setIndexSelect] = useState(-1);
  const [valueInput, setValueInput] = useState(
    indexSelect === -1 ? defaultOption : indexSelect
  );
  const [isDropDownOptions, setIsDropDownOptions] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isMathching, setIsMathching] = useState(false);

  const handleCLickOutSide = (e) => {
    const inputId = document.getElementById("select-input");
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setIsTyping(false);
      setIsDropDownOptions(false);
      checkIsMatching();
      inputId.removeEventListener("input", handleKeyEvent);
    }
  };

  const onClickInInput = () => {
    onChangeStateDropDown();
    checkIsMatching();
  };

  const onChangeStateDropDown = () => {
    setIsDropDownOptions(!isDropDownOptions);
  };

  const checkIsMatching = () => {
    if (valueInput === options[indexSelect]?.value) {
      setIsMathching(true);
    } else {
      setIsMathching(false);
    }
  };

  const onHandleInputChange = (e) => {
    setValueInput(e.target.value);
    setIsTyping(true);
    setIsDropDownOptions(true);
  };

  const onChooseOption = (indexOption, valueOption) => {
    setIndexSelect(indexOption);
    setValueInput(valueOption.value);
    setIsDropDownOptions(false);
  };

  const handleKeyEvent = (e) => {
    if (
      isMathching &&
      (e.key === "Backspace" || e.inputType === "deleteContentBackward")
    ) {
      setValueInput("");
      setIndexSelect(-1);
    }

    if (e.key === "Enter") {
      setValueInput(optionsValue[0]?.value);
      setIsDropDownOptions(false);
      setIsTyping(false);
    }
  };

  useEffect(() => {
    if (!isTyping) {
      setOptionsValue(options);
    } else {
      const filtered = options.filter((item) => {
        return item.value.toLowerCase().includes(valueInput.toLowerCase());
      });
      setOptionsValue(filtered);
    }
  }, [valueInput, isTyping, options]);

  useEffect(() => {
    checkIsMatching();
    const findIndex = options.findIndex(
      (option) => option.value === valueInput
    );
    setIndexSelect(findIndex);
    sendValueToParent(valueInput === defaultOption ? "" : valueInput);
  }, [valueInput, indexSelect]);

  useEffect(() => {
    const isOptionsContainValue = options.some(
      (option) => option.value === valueInput
    );
    if (!isOptionsContainValue && !isTyping && valueInput !== '') {
      setValueInput(defaultOption);
      sendValueToParent("");
    }
  }, [valueInput, options]);

  useEffect(() => {
    if (roomName === '') {
      setValueInput('');
      console.log(`setRoomName ${roomName}`);
      console.log('Room name is empty');
    }
  }, [roomName]);

  useEffect(() => {
    console.log(`valueInput ${JSON.stringify(valueInput)}`);
    const inputRef = document.getElementById("select-input");
    inputRef.addEventListener("input", handleKeyEvent);
    window.addEventListener("keydown", handleKeyEvent);
    window.addEventListener("mousedown", handleCLickOutSide);
    return () => {
      inputRef.removeEventListener("input", handleKeyEvent);
      window.removeEventListener("keydown", handleKeyEvent);
      window.removeEventListener("mousedown", handleCLickOutSide);
    };
  }, [isMathching, valueInput, indexSelect, optionsValue]);

  return (
    <>
      <div className="wrapper-select-input">
        <div
          className="container-select-input"
          ref={inputRef}
          style={{ width: widthSelect }}
        >
          <input
            id="select-input"
            type="text"
            placeholder={placeholder}
            value={valueInput}
            onClick={onClickInInput}
            onChange={(e) => onHandleInputChange(e)}
          />
          {isDropDownOptions && (
            <div className="dropdown" style={{ width: widthSelectDropdow }}>
              {optionsValue.map((item, index) => (
                <div
                  key={index}
                  style={{ borderBottom: index === (optionsValue.length -1)  ? "none" : "1px solid #E0E0E0" }}
                  className={classNames("dropdown-option", {
                    "current-select": valueInput === item.value,
                  })}
                  onClick={() => onChooseOption(index, item)}
                >
                  {item.name}
                </div>
              ))}
            </div>
          )}
          <img
            className="dropdown-select"
            src="/assets/icon-dropdown-select.svg"
            width={24}
            height={24}
            onClick={onChangeStateDropDown}
          />
        </div>
      </div>
    </>
  );
};

SelectInput.propTypes = {
  options: PropTypes.array.isRequired,
  defaultOption: PropTypes.string.isRequired,
  sendValueToParent: PropTypes.func,
  width: PropTypes.string,
};

export default SelectInput;
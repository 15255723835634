import React, { Fragment, useEffect, useState } from 'react';
import Menu from './menu';
import axios from 'axios';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import Footer from './footer';
import SelectInput from './common/selectInput';
import FixedBottomBar from './fixBottomBar';
import c from 'clipboard-js';
import { set } from 'lodash';

function splitAddress(address) {
  const addr = address.split(',');
  if (addr.length === 0) {
    return '';
  }
  let add = `${addr[0] + ',' + addr[1]}`;
  return add;
}

const handleResize = () => {
  return window.innerWidth <= 768;
};

const handleBack = () => {
  // Xử lý sự kiện khi nhấn nút Back
  console.log('Back button clicked');
};

function HomeStayV2() {
  const [homestay, setHomeStay] = useState([]);

  const [priceRooms, setPriceRooms] = useState([]);

  const [branch, setBranch] = useState('');
  const [roomName, setRoomName] = useState('');
  const [uniqueAddresses, setUniqueAddresses] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [roomsByAddress, setRoomsByAddress] = useState({});

  const handleAddressChange = () => {
    console.log(`branch ${branch}`);
    if(branch === '') {
      setDefaultRooms();
      setRoomName('');
      return;
    }

    let roomsN = homestay.filter((item) => item.branchName === branch);

    let newRooms = [];
    newRooms.push({
      name: 'Tất cả Phòng',
      value: '',
    });
    
    newRooms =   [...newRooms, ...Array.from(new Set(roomsN.map((item) => item.name))).map(
      (name) => {
        return {
          name: name,
          value: name,
        };
      }
    )];
    setRooms(newRooms);
  };

  const formatAddressDepartment = (listDepartment) => {
    const newDepartment = [];
    if (!listDepartment) return;
    listDepartment.map((item) => {
      if(item === 'Tất cả Chi Nhánh') {
        newDepartment.push({
          name: item,
          value: '',
        });
        return;
      }
      const splitItem = item.split(',');
      newDepartment.push({
        name: `${splitItem[0]},${splitItem[1]}`,
        value: item,
      });
    });
    return newDepartment;
  };

  useEffect(() => {
    const fetchHomeStay = async () => {
      try {
        let homestayResult = await axios.post(
          'https://bayla-be-main.vercel.app/room/search'
        );
        homestayResult = homestayResult?.data;
        console.log('🚀 ~ fetchHomeStay ~ homestayResult:', homestayResult);
        if (homestayResult?.code === 1000) {
          setHomeStay(homestayResult?.data?.rooms);
        }
      } catch (error) {
        console.log(
          `ERROR when call get list homestay ${
            error.message
          } -- ${JSON.stringify(error)}`
        );
      }
    };
    fetchHomeStay();
  }, []);

  const setDefaultRooms = () => {
    let newRooms = [];
    newRooms.push({
      name: 'Tất cả Phòng',
      value: '',
    });
      newRooms = [...newRooms ,... Array.from(new Set(homestay.map((item) => item.name))).map(
        (name) => {
          return {
            name: name,
            value: name,
          };
        }
      )];
      setRooms(newRooms);
  };
  

  useEffect(() => {
    let newAddresses = [];
    newAddresses.push('Tất cả Chi Nhánh');
     newAddresses = [...newAddresses,...new Set(homestay.map((h) => h.branchName))];
     console.log(`newAddresses ${JSON.stringify(newAddresses)}`);
     let newRooms = [];
     setDefaultRooms();
    const allRoomsByAddress = homestay.reduce((acc, curr) => {
      acc[curr.branchName] = acc[curr.branchName] || [];
      acc[curr.branchName].push(curr);
      return acc;
    }, {});

    setRoomsByAddress(allRoomsByAddress);
    setUniqueAddresses(formatAddressDepartment(newAddresses));
  }, [homestay]);

  useEffect(() => {
    if (branch || roomName) {
      const newRoomsByAddress = homestay.reduce((acc, curr) => {
        // Nếu chỉ roomName được chọn, chỉ thêm phòng đó
        if (roomName && !branch) {
          if (curr.name === roomName) {
            acc[curr.branchName] = acc[curr.branchName] || [];
            acc[curr.branchName].push(curr);
          }
        } else if (curr.branchName === branch) {
          acc[curr.branchName] = acc[curr.branchName] || [];

          // Nếu cả branch và roomName đã được chọn, chỉ thêm phòng đó
          if (roomName) {
            if (curr.name === roomName) {
              acc[curr.branchName].push(curr);
            }
          } else {
            acc[curr.branchName].push(curr);
          }
        }

        return acc;
      }, {});

      setRoomsByAddress(newRoomsByAddress);
    } else {
      const allRoomsByAddress = homestay.reduce((acc, curr) => {
        acc[curr.branchName] = acc[curr.branchName] || [];
        acc[curr.branchName].push(curr);
        return acc;
      }, {});

      setRoomsByAddress(allRoomsByAddress);
    }
  }, [branch, roomName]);
  useEffect(() => {
    console.log(`branch ${branch}`);
    setDefaultRooms();
      console.log(`setRoomName ${roomName}`);
      setRoomName('');
    handleAddressChange();
  }, [branch]);

  useEffect(() => {
    if (roomName === '') {
      setDefaultRooms();
      console.log(`setRoomName ${roomName}`);
      setRoomName('');
      console.log('Room name is empty');
    }
  }, [roomName]);

  return (
    <>
      <header className='header-section' >
        <div className='top-nav'>
          <div className='container'></div>
        </div>
        <div className='menu-item' >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4'>
                <div className={handleResize() == true ? 'fixed-logo' : 'logo'}>
                  <a href='/'>
                    <img
                      style={{
                        // marginTop: '10px',
                        maxWidth: '70%',
                      }}
                      src='assets/img/logo/logo-xanh.png'
                      alt=''
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className='spacer-height'></div>
      <div className='container'  style={{paddingBottom: '90px'}}>
        {uniqueAddresses && (
          <div className='filter'>
            <h2 style={{ color: '#1c1b1d' }}>Xem phòng</h2>
            <div className='dropdowns'>
              <SelectInput
              style={{ width: '170px' }}
                className='select-input'
                options={uniqueAddresses}
                placeholder={'Tất cả Chi Nhánh'}
                sendValueToParent={setBranch}
                widthDropdow={'300px'}
              />
              <SelectInput
                className='select-input'
                options={rooms}
                placeholder={'Tất cả Phòng'}
                sendValueToParent={setRoomName}
                widthDropdow={'200px'}
                roomName={roomName}
              />
            </div>
          </div>
        )}

        {Object.entries(roomsByAddress).map(([address, rooms], index) => {
          return (
            <div key={index}>
              <div className='container'>
                <div className='address'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='breadcrumb-text'>
                        <h5
                          style={{
                            fontWeight: 400,
                          }}
                        >
                          {splitAddress(address)}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                {rooms.map((item, index) => (
                  <div
                    key={index}
                    className='col-3'
                    style={{
                      flex: '0 0 45%',
                      display: 'flex',
                      marginLeft: '15px',
                    }}
                  >
                    <Link
                      to={`/room-detail?id=${item?.id}`}
                      // to={`/room-booking`}
                      state={{
                        data: { homeStay: item, from: new Date() },
                      }}
                      className='room-item'
                      //  style={{ color: "#fcead6" }}
                    >
                      <img
                        src={item?.images?.[0]}
                        alt=''
                        style={{ borderRadius: '10px' }}
                      />
                      <div className='ri-text' style={{ marginTop: '20px' }}>
                        <p
                          style={{
                            textAlign: 'left',
                            margin: '0',
                            padding: '0',
                            fontWeight: 'bold',
                          }}
                        >
                          {item?.name}
                        </p>
                        <p
                          style={{
                            textAlign: 'left',
                            fontSize: '10px',
                            margin: '0',
                            padding: '0',
                          }}
                        >
                          {numeral(item?.priceList.overNight || 0).format(
                            '0,0'
                          )}{' '}
                          VND/ Qua đêm
                        </p>
                        <p
                          style={{
                            textAlign: 'left',
                            fontSize: '10px',
                            margin: '0',
                            padding: '0',
                          }}
                        >
                          {numeral(item?.priceList.threeHours || 0).format(
                            '0,0'
                          )}{' '}
                          VND/ 3h
                        </p>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
      <FixedBottomBar onBack={handleBack} nameButtonNext='Tiếp Tục' />
      {/* <Footer /> */}
    </>
  );
}

export default HomeStayV2;

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../slide.css';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { EffectCoverflow, Pagination, Navigation } from 'swiper';
import { useNavigate } from 'react-router-dom';

const HomeStaySlide = (props) => {
  const navigate = useNavigate();

  const findHomeStay = async (homeStay) => {
    try {
      navigate(`/room-detail?id=${homeStay.id}`, { state: { data: { homeStay } } });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className='container-slide zoom-slide'
      style={{ position: 'relative', width: '100%' }}
    >
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{
          el: '.swiper-pagination',
          clickable: true,
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className='swiper_container'
      >
        {props.data.map((item, index) => {
          return (
            <SwiperSlide key={index} style={{ initialSlide: 1 }}>
              <div
                className='image-container'
                style={{
                  backgroundColor: 'rgb(210 197 241)',
                  borderRadius: '2rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '38em',
                  marginTop: '15%',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  overflow: 'visible',
                  position: 'relative', // Add this to position the button
                  initialSlide: 1,
                  border: '3px solid #fff',
                }}
                onClick={() => findHomeStay(item)}
              >
                <img
                  src={item?.images?.[0]}
                  alt='slide_image'
                  style={{
                    borderRadius: 'inherit',
                    width: '100%',
                    height: '50em',
                  }}
                />
                {/* Add button below the image */}
                <button
                  style={{
                    position: 'absolute', // To place the button at a specific location
                    bottom: '-5%', // Adjust this to move the button up/down
                    left: '50%',
                    transform: 'translateX(-50%)',
                    background: 'linear-gradient(to top right, #ffdab6, #fff)',
                    color: 'black',
                    border: 'none',
                    borderRadius: '20px',
                    padding: '10px 20px',
                    cursor: 'pointer',
                    fontSize: '13px',
                    fontWeight: 'bold',
                    width: '70%',
                  }}
                  onClick={() => findHomeStay(item)} // Action when button is clicked
                >
                  {item?.name} {/* You can dynamically insert item data here */}
                </button>
              </div>
            </SwiperSlide>
          );
        })}

        {/* Controls */}
        <div
          className='slider-controler'
          style={{
            position: 'absolute',
            top: '-5%',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 40px',
          }}
        >
          <div
            className='swiper-button-prev slider-arrow'
            onClick={() =>
              document.querySelector('.swiper-button-prev').click()
            }
          >
            <img
              src='/assets/icon-arrow-left.svg'
              alt='Previous'
              style={{
                width: '30px',
                height: '30px',
                opacity: ".5",
                transition: 'opacity .15s ease',
              }}
            />
          </div>
          <div
            className='swiper-button-next slider-arrow'
            onClick={() =>
              document.querySelector('.swiper-button-next').click()
            }
          >
            {' '}
            <img
              src='/assets/icon-arrow-right.svg'
              alt='Next'
              style={{
                width: '30px',
                height: '30px',
                opacity: ".5",
                transition: 'opacity .15s ease',
              }}
            />
          </div>
        </div>

        {/* Pagination */}
        <div
          className='swiper-pagination'
          style={{
            position: 'absolute',
            bottom: '10px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 10,
          }}
        ></div>
      </Swiper>
    </div>
  );
};

export default HomeStaySlide;

import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import 'bootstrap/dist/css/bootstrap.min.css';
// import leftIcon from 'path/to/new-left-icon.png';
// import rightIcon from 'path/to/new-right-icon.png';

const HomeStayCarousel = ({ homeStay }) => {
    return (
        <section className='hero-section-inner'>
            <div className='custom-carousel-container'>
                <Carousel
                    className='centered-carousel'
                    interval={5000000000}
                    fade
                    prevIcon={
                        <img 
                          src='/assets/icon-arrow-left.svg' 
                          alt="Previous" 
                          className="carousel-arrow" 
                        />
                      }
                    nextIcon={
                        <img 
                        src='/assets/icon-arrow-right.svg' 
                        alt="Previous" 
                        className="carousel-arrow" 
                      />
                    }
                >
                    {homeStay?.images?.map((item, index) => (
                        <Carousel.Item key={index}>
                           <img
                                    className='d-block w-100'
                                    src={item}
                                    alt=''
                                    style={{ height: '450px !important',objectFit: 'cover',  }}
                                                                    />
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
        </section>
    );
};

export default HomeStayCarousel;

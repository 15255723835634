import React, { Fragment, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import numeral from 'numeral';
import moment from 'moment-timezone';
import 'react-datepicker/dist/react-datepicker.css';
import _ from 'lodash';
import axios from 'axios';
import Compressor from 'compressorjs';
import FixedBottomBar from './fixBottomBar';
import HomeStayCarousel from './homeStayCarousel';
import { Grid2, Chip } from '@mui/material';
import HouseIcon from '@mui/icons-material/House';

function HomeStayDetailPK() {
  const [homeStay, setHomeStay] = useState({});
  const [totalPayment, setTotalPayment] = useState(0);
  const [isStatusScroll, setIsStatusScroll] = useState(true);
  const navigate = useNavigate();

  const handleResize = () => {
    return window.innerWidth <= 768;
  };

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const handleBack = () => {};
  const location = useLocation();
  const receivedData = location.state ? location.state.data : null;
  const navigateToDetail = () => {
    try {
      navigate(`/booking-detail?id=${receivedData?.homeStay?.id}`, {
        state: {
          data: {
            homeStay: receivedData?.homeStay
              ? receivedData?.homeStay
              : homeStay,
            bookingTIme: receivedData?.bookingTimeData
              ? receivedData?.bookingTimeData
              : [],
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleNext = (type) => {
    return navigateToDetail();
  };

  // Trong component của bạn
  let query = useQuery();
  let id = query.get('id'); // "101"

  // setHomeStay(receivedData?.data || {});

  useEffect(() => {
    setHomeStay(receivedData?.homeStay || {});
    const fetchHomeStay = async () => {
      try {
        const body = {
          id: id,
        };
        let home = await axios.post(
          `https://bayla-be-main.vercel.app/room/get-room-detail`,
          body,
          { timeout: 60000 }
        );
        setHomeStay(home?.data?.data[0]);
      } catch (error) {
        console.log(
          `ERROR when call get list homestay ${
            error.message
          } -- ${JSON.stringify(error)}`
        );
      }
    };
    if (_.isEmpty(receivedData)) {
      fetchHomeStay();
    }

    window.scrollTo(0, 0);

  }, []);

  return (
    <Fragment>
      <header className='header-section'>
        <div className='top-nav'>
          <div className='container'></div>
        </div>
        <div className='menu-item'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4'>
                <div className={handleResize() == true ? 'fixed-logo' : 'logo'}>
                  <a href='/'>
                    <img
                      style={{
                        // marginTop: '10px',
                        maxWidth: '70%',
                      }}
                      src='assets/img/logo/logo-xanh.png'
                      alt=''
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className='spacer-height' />
      <section className='room-details-section spad'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8'>
              <div className='room-details-item'>
                <HomeStayCarousel homeStay={homeStay} />
                <div className='rd-text' style={{ marginTop: '12%' }}>
                  <h3 style={{ fontFamily: 'Gilroy-Regular' }}>
                    {homeStay?.name}
                  </h3>
                  <h7 style={{ fontFamily: 'Gilroy-Regular' }}>
                    {homeStay?.address}
                  </h7>
                  <p style={{ fontSize: '12px' }}>
                  Bayla Home xin chào 😘 Homestay tự Checkin riêng tư tại Danang cho những couple hướng nội tìm chốn hẹn hò đây ạ 😘
                  </p>

                  {/* <p className="f-para">{homeStay?.description}</p> */}
                </div>
              </div>
              <Grid2
                container
                spacing={{ xs: 2, md: 2 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                justify='center'
              >
                 <Grid2 size={{ xs: 2, sm: 2, md: 4 }}>
                    <div style={{ textAlign: 'center', paddingBottom: '5px' }}>
                      {' '}
                      <Chip
                        style={{ width: '150px' }}
                        icon={<HouseIcon />}
                        label='Giường ngủ'
                        variant='outlined'
                      />
                    </div>
                  </Grid2>
                  <Grid2 size={{ xs: 2, sm: 2, md: 4 }}>
                    <div style={{ textAlign: 'center', paddingBottom: '5px' }}>
                      {' '}
                      <Chip
                        style={{ width: '150px' }}
                        icon={<HouseIcon />}
                        label='Bồn tắm'
                        variant='outlined'
                      />
                    </div>
                  </Grid2>
                  <Grid2 size={{ xs: 2, sm: 2, md: 4 }}>
                    <div style={{ textAlign: 'center', paddingBottom: '5px' }}>
                      {' '}
                      <Chip
                        style={{ width: '150px' }}
                        icon={<HouseIcon />}
                        label='Wc riêng'
                        variant='outlined'
                      />
                    </div>
                  </Grid2>
                  <Grid2  size={{ xs: 2, sm: 2, md: 4 }}>
                    <div style={{ textAlign: 'center', paddingBottom: '5px' }}>
                      {' '}
                      <Chip
                        style={{ width: '150px' }}
                        icon={<HouseIcon />}
                        label='Wifi'
                        variant='outlined'
                      />
                    </div>
                  </Grid2>
              </Grid2>
            </div>
          </div>
        </div>
      </section>

      <FixedBottomBar
        totalAmount={numeral(totalPayment).format('0,0')}
        onBack={handleBack}
        onNext={() => {
          if (isStatusScroll === false) {
            setIsStatusScroll(true);
            handleNext('detail');
          } else {
            handleNext();
          }
        }}
        nameButtonNext='Đặt Phòng'
      />
      {/* <!-- Footer Section Begin -->
      <Footer /> */}
      {/* <!-- Footer Section End --> */}
    </Fragment>
  );
}

export default HomeStayDetailPK;

import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import numeral from 'numeral';
import moment from 'moment-timezone';
import momentAdd from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import _, { set } from 'lodash';
import axios from 'axios';
import ReactLoadingOverlay from 'react-loading-overlay';

import {
  Row,
  Col,
  Form,
  InputGroup,
  FormControl,
  Image,
} from 'react-bootstrap';

import NumberOfPeopleInput from './numberPeopleInput';
import Compressor from 'compressorjs';
import FixedBottomBar from './fixBottomBar';
import TermsAndConditions from './termCondition';

function FillInformation() {
  const [homeStay, setHomeStay] = useState({});
  const [isLoadingBooking, setIsLoadingBooking] = useState(false);
  const [isErrorBooking, setErrorBooking] = useState(false);


  const [totalPayment, setTotalPayment] = useState(0);
  const [isLoadingTotalPayment, setIsLoadingTotalPayment] = useState(false);
  const [imagesCCCD, setImagesCCCD] = useState([]);
  const [checkedTerm, setCheckedTerm] = useState(false)
  const navigate = useNavigate();

  const handleResize = () => {
    return window.innerWidth <= 768;
  };
  const handleImageChange = async (index, file) => {
    const updatedImages = [...imagesCCCD];
    updatedImages[index] = file;
    if (updatedImages.length === 2) setErrorImageInfo('');
    setImagesCCCD(updatedImages);
  };

  const handleFileChange = (index, e) => {
    const file = e.target.files[0];
    handleImageChange(index, file);
  };

  const [numPeople, setNumPeople] = useState(2);
  const changeNumPeople = (data) => {
    setNumPeople(data);
  };

  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    phone: '',
    email: '',
    cccd: '',
    note: '',
  });

  const [errorsCustomerInfo, setErrorCustomerInfo] = useState({
    name: '',
    phone: '',
    email: '',
    cccd: '',
    note: '',
  });

  const handleChangeCustomerInfo = (e) => {
    const { name, value } = e.target;
    setCustomerInfo((prevData) => ({ ...prevData, [name]: value }));
    setErrorCustomerInfo((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const [showInputCCCD, setShowInputCCCD] = useState(false);

  const checkShowInputCCCD = (data) => {
    let isShow = false;
    if (!_.isEmpty(data)) {
      const overNightSlot = _.find(data?.bookingSlots || [], { order: 4 });
      if (overNightSlot) isShow = true;
    }

    setShowInputCCCD(isShow);

    return isShow;
  };

  const [bookDateData, setBookDateData] = useState([]);

  useEffect(() => {
    calTotalPayment();
  }, [bookDateData]);

  const [errorBookDateData, setErrorBookDateData] = useState({
    isValid: true,
    message: '',
  });

  const [showModalErrorBookData, setShowModalErrorBookData] = useState(false);

  const [show, setShow] = useState(false);



  const handleCloseModalError = () => {
    setShowModalErrorBookData(false);
  };

  const compressImages = async (file) => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.1, // Chất lượng hình ảnh sau khi nén (từ 0.1 đến 1.0)
        success(result) {
          // Đã nén xong, result là một Blob mới
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
    });
  };

  const bookHomeStay = async (event) => {
    event.preventDefault(); 

    console.log(`đang book Home Stay`);
    setIsLoadingBooking(true);
    try {
  
      setErrorBooking(false);

      // upload image cccd
      const uploadImageUrls = [];
      console.log(`đang upload hình ảnh`);
      for (let index = 0; index < imagesCCCD.length; index++) {
        try {
          const compressImageResult = await compressImages(imagesCCCD[index]);

          if (compressImageResult) {
            const formData = new FormData();
            formData.append('image', compressImageResult);
            console.log(`đang uploading...`);
            let response = await axios.post(
              'https://bayla-be-main.vercel.app/upload',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            );
            response = response?.data;
            if (response?.code === 1000) {
              uploadImageUrls.push(response?.data?.url);
            }
          }
        } catch (error) {
          console.log(
            `ERROR when upload images ${error.message} -- ${JSON.stringify(
              error
            )}`
          );
        }
      }

      let from;
      let to;
      let bookingList = _.sortBy(bookDateData, returnDateBookData);
      bookingList = _.map(bookingList, (item, index) => {
        const result = {
          date: item.date,
        };
        let bookingSlots = _.sortBy(item.bookingSlots, ['order']);
        if (index === 0) {
          from = bookingSlots?.[0]?.from;
        }
        if (index === bookingList.length - 1) {
          to = _.last(bookingSlots)?.to;
        }
        const slots = _.map(bookingSlots, (slot) => {
          return slot.order - 1;
        });
        result.slots = slots;
        return result;
      });

      const data = {
        roomId: homeStay.id,
        contactName: customerInfo.name,
        contactPhone: customerInfo.phone,
        contactEmail: customerInfo.email,
        contactChannel: 'Zalo',
        totalCustomer: numPeople,
        identifyCardNumber: uploadImageUrls,
        bookingList,
        note: customerInfo.note,
      };
      console.log(`đang gọi booking`);
      let response = await axios.post(
        'https://bayla-be-main.vercel.app/booking/v22',
        data
      );
      response = response?.data;
      let bookingResponse = {};
      if (response?.code === 1000) {
        response = response?.data;
        bookingResponse = {
          bookingId: response?.bookingId,
          qrContent: response.qrContent,
          bankInfo: response?.bankInfo,
          amount: response?.amount,
          url: response?.url,
          from: `${moment(from)
            .tz('Asia/Ho_Chi_Minh')
            .format('DD/MM/YYYY HH:mm')}`,
          to: `${moment(to).tz('Asia/Ho_Chi_Minh').format('DD/MM/YYYY HH:mm')}`,
          expired: momentAdd(moment(new Date()).tz('Asia/Ho_Chi_Minh')).add(
            360,
            'minutes'
          ),
        };
        console.log(`gọi xong booking`);
        nextStep(bookingResponse);
       
      
      } else {
        setErrorBooking(true);
      }
    } catch (error) {
      setErrorBooking(true);
      console.log(
        `ERROR when call /booking   ${error.message} -- ${JSON.stringify(
          error
        )}`
      );
    }
    setIsLoadingBooking(false);
  };
  const returnDateBookData = (item) => {
    return moment(item.date, 'DD-MM-YYYY').toDate();
  };
  const calTotalPayment = async () => {
    try {
      setIsLoadingTotalPayment(true);

      if (_.isEmpty(bookDateData)) {
        setTotalPayment(0);
        setIsLoadingTotalPayment(false);
        return;
      }
      let bookingList = _.sortBy(bookDateData, returnDateBookData);
      bookingList = _.map(bookingList, (item) => {
        const result = {
          date: item.date,
        };
        let bookingSlots = _.sortBy(item.bookingSlots, ['order']);
        const slots = _.map(bookingSlots, (slot) => {
          return slot.order - 1;
        });
        result.slots = slots;
        return result;
      });

      const data = {
        roomId: homeStay.id,
        totalCustomer: numPeople,
        bookingList,
        isCalAmountOnly: true,
      };

      let response = await axios.post(
        'https://bayla-be-main.vercel.app/booking/v2',
        data
      );
      response = response?.data;
      if (response?.code === 1000) {
        setTotalPayment(response?.data?.total);
      }
    } catch (error) {
      setTotalPayment(0);
    }
    setIsLoadingTotalPayment(false);
  };

  const checkValidCustomerInfo = (customerInfo) => {
    console.log(`đang check valid customer info`);
    const { name, phone, email, cccd } = customerInfo;
    let isValid = true;
    if (_.isEmpty(_.trim(name))) {
      setErrorCustomerInfo((prevErrors) => ({
        ...prevErrors,
        name: 'Vui lòng nhập tên',
      }));
      isValid = false;
    }

    const phoneRegex = /^(0[2-9]|84[2-9]|\+84[2-9])\d{8,9}$/;
    if (_.isEmpty(_.trim(phone))) {
      setErrorCustomerInfo((prevErrors) => ({
        ...prevErrors,
        phone: 'Vui lòng nhập thông tin số điện thoại',
      }));
      isValid = false;
    }
    if (!phoneRegex.test(phone)) {
      setErrorCustomerInfo((prevErrors) => ({
        ...prevErrors,
        phone: 'Số điện thoại không đúng định dạng',
      }));
      isValid = false;
    }

    const emailRegex = /^[a-zA-Z0-9._-]+@(gmail\.com|icloud\.com)$/;
    if (_.isEmpty(_.trim(email))) {
      setErrorCustomerInfo((prevErrors) => ({
        ...prevErrors,
        email: 'Vui lòng nhập thông tin email',
      }));
      isValid = false;
    }
    if (!emailRegex.test(email)) {
      setErrorCustomerInfo((prevErrors) => ({
        ...prevErrors,
        email:
          'Email không đúng định dạng. (Định dạng đúng: ...@gmail.com hoặc ...@icloud.com)',
      }));
      isValid = false;
    }

    // if (checkShowInputCCCD(bookDateData) && _.isEmpty(_.trim(cccd))) {
    //     setErrorCustomerInfo((prevErrors) => ({ ...prevErrors, cccd: 'Vui lòng nhập thông tin cccd' }))
    //     isValid = false;
    // }

    return isValid;
  };

  const [errorImageInfo, setErrorImageInfo] = useState('');
  const [errorTermInfo, setErrorTermInfo] = useState('')
  const checkValidImagesCCCD = () => {
    let isValid = true;
    if (imagesCCCD.length !== 2) {
      isValid = false;
      setErrorImageInfo('Thiếu thông tin hình CCCD');
    }

    return isValid;
  };
  const checkValidTerm = () => {
    let isValid = true
    if(!checkedTerm) {
      isValid = false
      setErrorTermInfo("Vui lòng chấp nhận điều khoản trước khi đặt phòng")
    }
    return isValid
  }

  const actionSubmitForm = (e) => {
    
    if (e) {
      e.preventDefault();
    }


    const isValidCustomerInfo = checkValidCustomerInfo(customerInfo);

    if (!isValidCustomerInfo) return;

    if (!checkValidImagesCCCD()) return;
  
    if(!checkValidTerm()) return

    bookHomeStay(e);
  };

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const nextStep = (bookingResponse) => {
    console.log('Next step called with bookingResponse:', bookingResponse);
  
    try {
      const sanitizedHomeStay = JSON.parse(JSON.stringify(homeStay));
      const sanitizedBookingResponse = JSON.parse(JSON.stringify(bookingResponse));
  
  
      navigate('/qr-page', {
        state: { data: { homeStay: sanitizedHomeStay, bookingResponse: sanitizedBookingResponse, customerInfo: customerInfo } }
      });
    } catch (error) {
      console.log('Failed to serialize state:', error);
    }
  };

  const handleBack = () => {};

  const handleNext = (e) => {
    // Xử lý sự kiện khi nhấn nút Next
    // navigate('#')
    actionSubmitForm(e);

    console.log('Next button clicked');
  };

  // Trong component của bạn
  let query = useQuery();
  let id = query.get('id'); // "101"

  const location = useLocation();
  const receivedData = location.state ? location.state.data : null;
  // setHomeStay(receivedData?.data || {});

  useEffect(() => {
   

    window.scrollTo(0, 0);

    setBookDateData(receivedData?.bookingTIme || []);

    setHomeStay(receivedData?.homeStay || {});

    checkShowInputCCCD(receivedData?.bookingTIme || {});

  }, []);

  return (
    <ReactLoadingOverlay
      active={isLoadingBooking}
      spinner={false}
      // text={"Đang tạo mã QR thanh toán, vui lòng\n chờ trong giây lát!"}
      styles={{
        overlay: (base) => ({
          ...base,
          background: 'rgba(0, 0, 0, 0.5)',
        }),
        spinner: (base) => ({
          ...base,
          width: '100px',
          height: '100px',
        }),
        content: (base) => ({
          ...base,
          display: 'none'
        //   color: '#fff',
        // fontSize: '16px',
        // padding: '10px',
        // whiteSpace: 'pre-line',
        }),
      }}
    >{isLoadingBooking && (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        padding: '20px',
        borderRadius: '10px',
        position: 'fixed',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1000,
        marginBottom: '100px',
      }}>
      <style>
    {`
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `}
  </style>
      <div style={{
  width: '80px',
  height: '80px',
  border: '5px solid rgba(255, 255, 255, 0.3)',
  borderTop: '5px solid #f7d672',
  borderRadius: '50%',
  animation: 'spin 1s linear infinite',
  marginBottom: '10px',
  zIndex: 1000, // Đảm bảo vòng tròn nằm trên các phần tử khác
  // backgroundColor: 'white' // Đảm bảo nền rõ ràng
}} />
        <div style={{
          color: '#fff',
          fontSize: '16px',
          padding: '10px',
          whiteSpace: 'pre-line',
          textAlign: 'center'
        }}>
          {"Đang tạo mã QR thanh toán, vui lòng\n chờ trong giây lát!"}
        </div>
      </div>
    )}
       <Fragment>
      <header className='header-section'>
        <div className='top-nav'>
          <div className='container'></div>
        </div>
        <div className='menu-item'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4'>
                <div className={handleResize() == true ? 'fixed-logo' : 'logo'}>
                  <a href='/'>
                    <img
                      style={{
                        // marginTop: '10px',
                        maxWidth: '70%',
                      }}
                      src='assets/img/logo/logo-xanh.png'
                      alt=''
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className='spacer-height'   style={{marginTop: '-40px', padding: 'unset'}}/>
      <section className='room-details-section spad'>
        <div className='container'>
          <div className='row' >
            <div className='col-lg-4'>
              <div className='room-booking'>
                <div className='container'>
                  <div className='address' style={{ color: '#111111' }}>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <div className='breadcrumb-text'>
                          <h5
                            style={{
                              fontWeight: 'bold',
                              color: '#111111',
                            }}
                          >
                            Thông tin đặt phòng
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Form onSubmit={actionSubmitForm}>
                  <Row className='mb-3' >

                    <div className='select-option' style={{ width: '100%' }}>
                      <Form.Group as={Col} >
                        <Form.Label style={{ fontWeight: 'bold' }}>
                          Họ Tên:{' '}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder=''
                          name='name'
                          value={customerInfo.name}
                          onChange={handleChangeCustomerInfo}
                          isInvalid={!!errorsCustomerInfo.name}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errorsCustomerInfo.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className='select-option' style={{ width: '100%' }}>
                      <Form.Group as={Col}>
                        <Form.Label style={{ fontWeight: 'bold' }}>
                          Số điện thoại:
                        </Form.Label>
                        <Form.Control
                          type='tel'
                          placeholder=''
                          name='phone'
                          value={customerInfo.phone}
                          onChange={handleChangeCustomerInfo}
                          isInvalid={!!errorsCustomerInfo.phone}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errorsCustomerInfo.phone}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className='select-option' style={{ width: '100%' }}>
                      <Form.Group as={Col}>
                        <Form.Label style={{ fontWeight: 'bold' }}>
                          Email:
                        </Form.Label>
                        <Form.Control
                          type='email'
                          placeholder=''
                          name='email'
                          value={customerInfo.email}
                          onChange={handleChangeCustomerInfo}
                          isInvalid={!!errorsCustomerInfo.email}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errorsCustomerInfo.email}
                        </Form.Control.Feedback>
                        {/* <p style={{ fontSize: '12px',  marginTop: '15px', marginBottom: '0px', fontStyle: 'italic' }}> * Home khuyến khích sử dụng gmail và nhập đúng địa chỉ email để thông tin gửi đến bạn chính xác hơn ạ!.</p> */}
                      </Form.Group>
                    </div>

                    <div
                      className='select-option'
                      style={{ marginBottom: '0px', width: '100%' }}
                    >
                      <Form.Group as={Col}>
                        <NumberOfPeopleInput change={changeNumPeople} />
                        <p
                          style={{
                            fontSize: '12px',
                            marginTop: '-15px',
                            marginBottom: '10px',
                            fontStyle: 'italic',
                          }}
                        >
                          {' '}
                          *Từ khách thứ 3 trở lên, Home xin phép phụ thu 50k/khách ạ!
                        </p>
                      </Form.Group>
                    </div>

                    <div
                      className='select-option'
                      style={{ width: '100%'}}
                    >
                      <Form.Group as={Col}>
                        <Form.Label style={{ fontWeight: 'bold' }}>
                          {' '}
                          <span style={{ color: '#fffaf5' }}>*</span> Hình
                          CCCD/CMND (mặt trước/sau)
                        </Form.Label>
                        <Row>
                          {_.map(imagesCCCD, (image, index) => (
                            <Col xs={6} sm={6} md={6}>
                              <InputGroup
                                key={index}
                                className='mb-3'
                                style={{ height: '100px' }}
                              >
                                <FormControl
                                  type='file'
                                  accept='image/*'
                                  style={{ display: 'none' }}
                                  onChange={(e) => handleFileChange(index, e)}
                                />
                                <label
                                  htmlFor={`file-input-${index}`}
                                  style={{
                                    position: 'relative',
                                    cursor: 'pointer',
                                    height: '100px',
                                    border: '1px solid #ced4da',
                                  }}
                                >
                                  <Image
                                    src={
                                      image ? URL.createObjectURL(image) : ''
                                    }
                                    alt={`Ảnh ${index + 1}`}
                                    fluid
                                    style={{
                                      cursor: 'pointer',
                                      width: '100%',
                                      height: '100%',
                                    }}
                                  />
                                  <span
                                    style={{
                                      position: 'absolute',
                                      top: '50%',
                                      left: '50%',
                                      transform: 'translate(-50%, -50%)',
                                      cursor: 'pointer',
                                      zIndex: 2,
                                    }}
                                  ></span>
                                  <span style={{ zIndex: 1 }}>
                                    <FormControl
                                      type='file'
                                      accept='image/*'
                                      id={`file-input-${index}`}
                                      onChange={(e) =>
                                        handleFileChange(index, e)
                                      }
                                      style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        opacity: 0,
                                      }}
                                    />
                                  </span>
                                </label>
                                {/* <InputGroup.Text onClick={() => handleRemoveImage(index)} style={{ cursor: 'pointer' }}>
                                                                    &#10006;
                                                                </InputGroup.Text> */}
                              </InputGroup>
                            </Col>
                          ))}

                          {/* {_.map(imagesCCCD, (image, index) => ())} */}
                          {imagesCCCD.length === 0 ? (
                            <>
                              <Col xs={6} sm={6} md={6}>
                                <InputGroup
                                  className='mb-3'
                                  style={{ width: '100%', height: '100px' }}
                                >
                                  <FormControl
                                    type='file'
                                    accept='image/*'
                                    style={{ display: 'none' }}
                                    onChange={(e) =>
                                      handleFileChange(imagesCCCD.length, e)
                                    }
                                  />
                                  <label
                                    htmlFor={`file-input-${imagesCCCD.length}`}
                                    style={{
                                      position: 'relative',
                                      cursor: 'pointer',
                                      width: '100%',
                                      height: '100px',
                                      border: '1px solid #f7d672',
                                      borderRadius: '20px',
                                    }}
                                  >
                                    <span
                                      style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      Mặt trước
                                    </span>
                                    <span style={{ zIndex: 1 }}>
                                      <FormControl
                                        type='file'
                                        accept='image/*'
                                        id={`file-input-${imagesCCCD.length}`}
                                        onChange={(e) =>
                                          handleFileChange(imagesCCCD.length, e)
                                        }
                                        style={{
                                          position: 'absolute',
                                          top: 0,
                                          left: 0,
                                          width: '100%',
                                          height: '100%',
                                          opacity: 0,
                                        }}
                                      />
                                    </span>
                                  </label>
                                </InputGroup>
                              </Col>
                              <Col xs={6} sm={6} md={6}>
                                <InputGroup
                                  className='mb-3'
                                  style={{ width: '100%', height: '100px' }}
                                >
                                  <FormControl
                                    type='file'
                                    accept='image/*'
                                    style={{ display: 'none' }}
                                    onChange={(e) =>
                                      handleFileChange(imagesCCCD.length, e)
                                    }
                                  />
                                  <label
                                    htmlFor={`file-input-${imagesCCCD.length}`}
                                    style={{
                                      position: 'relative',
                                      cursor: 'pointer',
                                      width: '100%',
                                      height: '100px',
                                      border: '1px solid #f7d672',
                                      borderRadius: '20px',
                                    }}
                                  >
                                    <span
                                      style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      Mặt sau
                                    </span>
                                    <span style={{ zIndex: 1 }}>
                                      <FormControl
                                        type='file'
                                        accept='image/*'
                                        id={`file-input-${imagesCCCD.length}`}
                                        onChange={(e) =>
                                          handleFileChange(imagesCCCD.length, e)
                                        }
                                        style={{
                                          position: 'absolute',
                                          top: 0,
                                          left: 0,
                                          width: '100%',
                                          height: '100%',
                                          opacity: 0,
                                        }}
                                      />
                                    </span>
                                  </label>
                                </InputGroup>
                              </Col>
                            </>
                          ) : imagesCCCD.length === 1 ? (
                            <Col xs={6} sm={6} md={6}>
                              <InputGroup
                                className='mb-3'
                                style={{ width: '100%', height: '100px' }}
                              >
                                <FormControl
                                  type='file'
                                  accept='image/*'
                                  style={{ display: 'none' }}
                                  onChange={(e) =>
                                    handleFileChange(imagesCCCD.length, e)
                                  }
                                />
                                <label
                                  htmlFor={`file-input-${imagesCCCD.length}`}
                                  style={{
                                    position: 'relative',
                                    cursor: 'pointer',
                                    width: '100%',
                                    height: '100px',
                                    border: '1px solid #f7d672',
                                  }}
                                >
                                  <span
                                    style={{
                                      position: 'absolute',
                                      top: '50%',
                                      left: '50%',
                                      transform: 'translate(-50%, -50%)',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    Mặt sau
                                  </span>
                                  <span style={{ zIndex: 1 }}>
                                    <FormControl
                                      type='file'
                                      accept='image/*'
                                      id={`file-input-${imagesCCCD.length}`}
                                      onChange={(e) =>
                                        handleFileChange(imagesCCCD.length, e)
                                      }
                                      style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        opacity: 0,
                                      }}
                                    />
                                  </span>
                                </label>
                              </InputGroup>
                            </Col>
                          ) : (
                            <></>
                          )}
                        </Row>
                        {!_.isEmpty(errorImageInfo) && (
                          <p
                            style={{
                              fontSize: '14px',
                              color: 'red',
                              margin: '0px',
                              marginTop: '-10px',
                            }}
                          >
                            {' '}
                            {errorImageInfo}
                          </p>
                        )}
                      </Form.Group>
                    </div>

                    <div className='select-option' style={{ width: '100%' }}>
                      <Form.Group as={Col}>
                        <Form.Label style={{ fontWeight: 'bold' }}>
                          Ghi chú:
                        </Form.Label>
                        <Form.Control
                          // as="textarea"
                          row={5}
                          placeholder=''
                          name='note'
                          value={customerInfo.note}
                          onChange={handleChangeCustomerInfo}
                          isInvalid={!!errorsCustomerInfo.note}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errorsCustomerInfo.note}
                        </Form.Control.Feedback>
                        {/* <p style={{ fontSize: '12px', color: '#fffaf5', marginTop: '15px', marginBottom: '5px', fontStyle: 'italic' }}> Khi bấm đặt Homestay đồng nghĩa với việc bạn đã đọc và đồng ý với các <a href={homeStay?.rule} target="_blank" style={{ fontSize: '12px', color: '#fffaf5' }} rel="noreferrer"><span style={{ textDecoration: 'underline' }}>Nội quy</span></a> & <a href="https://drive.google.com/file/d/1ExI_EQrzM0ylujRJazpGwzHA5Bs_r24Q/view?usp=sharing" target="_blank" style={{ fontSize: '12px', color: '#fffaf5' }} rel="noreferrer"><span style={{ textDecoration: 'underline' }}>Chính sách</span></a> của Bayla Home Danang</p> */}
                      </Form.Group>
                    </div>
                    <div className='select-option' style={{ width: '100%' }}>
                      {!checkedTerm && (
                            <p
                              style={{
                                fontSize: '14px',
                                color: 'red',
                                margin: '0px',
                                marginTop: '-10px',
                                textAlign: "center"
                              }}
                            >
                              {' '}
                              {errorTermInfo}
                            </p>
                      )}
                      <TermsAndConditions homeStay={homeStay} sendStateChecked={setCheckedTerm}/>
                    </div>
                  </Row>
                </Form>


              </div>
            </div>
          </div>
        </div>
      </section>


      !isLoadingBooking &&   <FixedBottomBar
        totalAmount={numeral(totalPayment).format('0,0')}
        onBack={1}
        onNext={(event) => actionSubmitForm(event)}
        nameButtonNext={'Tiếp tục'}
        isLoadingBooking={isLoadingBooking}
      />

     
    </Fragment>
    </ReactLoadingOverlay>
   
  );
}

export default FillInformation;

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ContactFooter from './contract-footer';

const handleResize = () => {
  return window.innerWidth <= 768;
};
function Footer( { isShow = true}, props,) {
  const { siteInfo = {} } = props;
  var infor = siteInfo;

  return (
    <footer className='footer-section' style={{ padding: '5px 0', display: 'block'}}>
      <div className='container'>
           <ContactFooter/>

          </div>
    </footer>
  );
}

export default Footer;

import React, { useState, useEffect, useRef, Fragment } from "react";
import { Table, Spinner } from "react-bootstrap";
import moment from "moment";
import _ from "lodash";
import axios from "axios";
import c from "clipboard-js";

const AvailableAllRoom = (props) => {
  const [loading, setLoading] = useState(false);
  const [homestay, setHomeStay] = useState([]);
  const [timeSlotHeader, setTimeSlotHeader] = useState([]);
  const [data, setData] = useState([]);
  const [bookingTimeData, setBookingTimeData] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);
  // let prevScrollLeft = 0;

  const [branchList, setBranchList] = useState([]);

  const [startDate, setStartDate] = useState(new Date());

  const fetchDay = 20;

  const covertDayOfWeek = (dayOfWeek) => {
    const convertedValue = parseInt(dayOfWeek, 10) % 7;
    return convertedValue === 0 ? "CN" : (convertedValue + 1).toString();
  };

  function isBorder(index) {
    return (index + 1) % 4 === 0;
  }

  const handleChooseFullDay = (item) => {};

  const getDepartmentListName = () => {
    const listDepartment = [];
    homestay.filter((item) => listDepartment.push(item.branchName));
    return listDepartment;
  };

  const handleOnClickItem = (date, bookingSlot) => {
    let newBooking = _.cloneDeep(bookingTimeData) || [];
    let isSetBookingData = false;

    let existIndex = _.findIndex(newBooking, { date });
    if (existIndex === -1) {
      if (!_.isEmpty(newBooking)) {
        const roomId = newBooking?.[0]?.roomId;
        if (bookingSlot.roomId !== roomId) return;
        const nextDay = moment(date, "DD-MM-YYYY")
          .add(1, "days")
          .format("DD-MM-YYYY");
        const prevDay = moment(date, "DD-MM-YYYY")
          .subtract(1, "days")
          .format("DD-MM-YYYY");
        switch (bookingSlot.order) {
          case 1: {
            const existPrevDay = _.find(newBooking, { date: prevDay });
            if (!existPrevDay) return;
            const existOrder4 = _.find(existPrevDay.bookingSlots, { order: 4 });
            if (!existOrder4) return;
            break;
          }

          case 4: {
            const existNextDay = _.find(newBooking, { date: nextDay });
            if (!existNextDay) return;
            const existOrder1 = _.find(existNextDay.bookingSlots, { order: 1 });
            if (!existOrder1) return;
            break;
          }
          default:
            return;
        }
      }

      newBooking.push({
        roomId: bookingSlot.roomId,
        date,
        bookingSlots: [bookingSlot],
      });
      isSetBookingData = true;
    } else {
      let booking = _.cloneDeep(newBooking[existIndex]);
      const findSlot = _.find(booking.bookingSlots, {
        order: bookingSlot.order,
      });
      if (findSlot) {
        switch (bookingSlot.order) {
          case 2: {
            if (
              _.find(booking.bookingSlots, { order: 1 }) &&
              _.find(booking.bookingSlots, { order: 3 })
            )
              return;
            break;
          }
          case 3: {
            if (
              _.find(booking.bookingSlots, { order: 2 }) &&
              _.find(booking.bookingSlots, { order: 4 })
            )
              return;
            break;
          }
          case 1: {
            const prevDay = moment(date, "DD-MM-YYYY")
              .subtract(1, "days")
              .format("DD-MM-YYYY");
            if (
              _.find(booking.bookingSlots, { order: 2 }) &&
              _.find(newBooking, { date: prevDay })
            )
              return;
            break;
          }
          case 4: {
            const nextDay = moment(date, "DD-MM-YYYY")
              .add(1, "days")
              .format("DD-MM-YYYY");
            if (
              _.find(booking.bookingSlots, { order: 3 }) &&
              _.find(newBooking, { date: nextDay })
            )
              return;
            break;
          }
          default:
            return;
        }

        if (booking.roomId !== bookingSlot.roomId) return;
        _.remove(booking.bookingSlots, { order: bookingSlot.order });
      } else {
        switch (bookingSlot.order) {
          case 1: {
            if (!_.find(booking.bookingSlots, { order: 2 })) return;
            break;
          }
          case 4: {
            if (!_.find(booking.bookingSlots, { order: 3 })) return;
            break;
          }
          case 2: {
            if (
              !_.find(booking.bookingSlots, { order: 3 }) &&
              !_.find(booking.bookingSlots, { order: 1 })
            )
              return;
            break;
          }
          case 3: {
            if (
              !_.find(booking.bookingSlots, { order: 4 }) &&
              !_.find(booking.bookingSlots, { order: 2 })
            )
              return;
            break;
          }
          default:
            return;
        }
        booking.bookingSlots.push(bookingSlot);
      }

      if (_.isEmpty(booking.bookingSlots)) {
        _.remove(newBooking, { date });
      } else {
        newBooking[existIndex] = booking;
      }
      isSetBookingData = true;
    }

    if (isSetBookingData) {
      setBookingTimeData(newBooking);
      props?.onChooseTime(newBooking);
    }
  };

  const checkClick = (date, bookingItem) => {
    const exist = _.find(bookingTimeData, { date: date });

    if (exist && exist.roomId === bookingItem.roomId) {
      const findSlot = _.find(exist.bookingSlots, { order: bookingItem.order });
      if (findSlot) return true;
    }
    return false;
  };

  const tableRef = useRef(null);

  const fetchData = async (start, homestayProps) => {
    try {
      // Simulate fetching data from an API
      setLoading(true);

      setFilterLoading(true);

      const from = moment(start || startDate).toISOString();
      const to = moment(start || startDate)
        .add(fetchDay, "days")
        .toISOString();
        console.log(`props.data.branchId ${JSON.stringify(props.data.branchId)}`);
      const data =
        props.data.branchId === ""
          ? {
              from,
              to,
              roomId: props.data.roomId ?? undefined,
            }
          : {
              from,
              to,
              branchId: props.data.branchId ?? undefined,
              roomId: props.data.roomId ?? undefined,
            };
            console.log(`props.data.branchId ${JSON.stringify(props.data.branchId)}`);
            console.log(`requestData ${JSON.stringify(data)}`);
      const homestayList = homestayProps || homestay;

      let response = await axios.post(
        "https://bayla-be-main.vercel.app/room/checkAvailable",
        data
      );
      response = response?.data;
      let newData = [];
      if (response?.code === 1000) {
        newData = response?.data || [];

        const newFormat = [];

        _.forEach(newData, (data) => {
          const homeStayInfo = _.find(homestayList, { id: data?.roomId });

          _.forEach(data.dateAvailable, (dateAvailable) => {
            const existDate = _.find(newFormat, {
              date2: moment(dateAvailable?.date).format("DD-MM-YYYY"),
            });
            if (existDate) {
              const obj = {
                time1: {
                  ...dateAvailable?.bookingTimeSlots?.[0],
                  roomId: data.roomId,
                  order: 1,
                },
                time2: {
                  ...dateAvailable?.bookingTimeSlots?.[1],
                  roomId: data.roomId,
                  order: 2,
                },
                time3: {
                  ...dateAvailable?.bookingTimeSlots?.[2],
                  roomId: data.roomId,
                  order: 3,
                },
                time4: {
                  ...dateAvailable?.bookingTimeSlots?.[3],
                  roomId: data.roomId,
                  order: 4,
                },
                color: homeStayInfo?.branchColor,
              };

              existDate.data.push(obj);
            } else {
              const obj = {
                date1: covertDayOfWeek(moment(dateAvailable?.date).format("e")),
                date2: moment(dateAvailable?.date).format("DD-MM-YYYY"),
                data: [
                  {
                    time1: {
                      ...dateAvailable?.bookingTimeSlots?.[0],
                      roomId: data.roomId,
                      order: 1,
                    },
                    time2: {
                      ...dateAvailable?.bookingTimeSlots?.[1],
                      roomId: data.roomId,
                      order: 2,
                    },
                    time3: {
                      ...dateAvailable?.bookingTimeSlots?.[2],
                      roomId: data.roomId,
                      order: 3,
                    },
                    time4: {
                      ...dateAvailable?.bookingTimeSlots?.[3],
                      roomId: data.roomId,
                      order: 4,
                    },
                    color: homeStayInfo?.branchColor,
                  },
                ],
              };
              newFormat.push(obj);
            }
          });
        });

        newData = newFormat;
        setStartDate(moment(to).add(1, "days"));
        setData((prevData) =>
          // props.data.branchId ? [...newData] : [...prevData, ...newData]
          [...newData]
        );

        let header = _.map(newFormat?.[0]?.data, (item) => {
          return [
            {
              value: `${item.time1.startTime} - ${item.time1.endTime}`,
              // color: item.color,
            },
            {
              value: `${item.time2.startTime} - ${item.time2.endTime}`,
              // color: item.color,
            },
            {
              value: `${item.time3.startTime} - ${item.time3.endTime}`,
              // color: item.color,
            },
            {
              value: `${item.time4.startTime} - ${item.time4.endTime}`,
              // color: item.color,
            },
          ];
        });
        header = _.flattenDeep(header);
        setTimeSlotHeader(header);
      }
    } catch (error) {
      console.log(
        `[ERROR] => call api /room/checkAvailable error ${
          error.message
        } -- ${JSON.stringify(error)}`
      );
    } finally {
      setFilterLoading(false);
    }

    setLoading(false);
  };

  const fetchDataLoadMore = async (start, homestayProps) => {
    try {
      setLoading(true);

      const from = moment(start || startDate).toISOString();
      const to = moment(start || startDate)
        .add(fetchDay, "days")
        .toISOString();

      const requestData =
        props.data.branchId === ""
          ? {
              from,
              to,
              roomId: props.data.roomId ?? undefined,
            }
          : {
              from,
              to,
              branchId: props.data.branchId ?? undefined,
              roomId: props.data.roomId ?? undefined,
            };
      console.log(`requestData ${JSON.stringify(requestData)}`);
      const homestayList = homestayProps || homestay;

      let response = await axios.post(
        "https://bayla-be-main.vercel.app/room/checkAvailable",
        requestData
      );
      response = response?.data;
      let newData = [];
      if (response?.code === 1000) {
        newData = response?.data || [];

        const newFormat = [];

        _.forEach(newData, (data) => {
          const homeStayInfo = _.find(homestayList, { id: data?.roomId });

          _.forEach(data.dateAvailable, (dateAvailable) => {
            const existDate = _.find(newFormat, {
              date2: moment(dateAvailable?.date).format("DD-MM-YYYY"),
            });
            if (existDate) {
              const obj = {
                time1: {
                  ...dateAvailable?.bookingTimeSlots?.[0],
                  roomId: data.roomId,
                  order: 1,
                },
                time2: {
                  ...dateAvailable?.bookingTimeSlots?.[1],
                  roomId: data.roomId,
                  order: 2,
                },
                time3: {
                  ...dateAvailable?.bookingTimeSlots?.[2],
                  roomId: data.roomId,
                  order: 3,
                },
                time4: {
                  ...dateAvailable?.bookingTimeSlots?.[3],
                  roomId: data.roomId,
                  order: 4,
                },
                color: homeStayInfo?.branchColor,
              };

              existDate.data.push(obj);
            } else {
              const obj = {
                date1: covertDayOfWeek(moment(dateAvailable?.date).format("e")),
                date2: moment(dateAvailable?.date).format("DD-MM-YYYY"),
                data: [
                  {
                    time1: {
                      ...dateAvailable?.bookingTimeSlots?.[0],
                      roomId: data.roomId,
                      order: 1,
                    },
                    time2: {
                      ...dateAvailable?.bookingTimeSlots?.[1],
                      roomId: data.roomId,
                      order: 2,
                    },
                    time3: {
                      ...dateAvailable?.bookingTimeSlots?.[2],
                      roomId: data.roomId,
                      order: 3,
                    },
                    time4: {
                      ...dateAvailable?.bookingTimeSlots?.[3],
                      roomId: data.roomId,
                      order: 4,
                    },
                    color: homeStayInfo?.branchColor,
                  },
                ],
              };
              newFormat.push(obj);
            }
          });
        });

        newData = newFormat;

        setStartDate(moment(to).add(1, "days"));
        setData((prevData) => {
          // Lấy danh sách các ngày đã tồn tại từ prevData
          const existingDates = new Set(prevData.map((item) => item.date2));

          // Lọc newData để loại bỏ các ngày đã tồn tại
          const filteredNewData = newData.filter(
            (item) => !existingDates.has(item.date2)
          );

          return [...prevData, ...filteredNewData];
        });

        let header = _.map(newFormat?.[0]?.data, (item) => {
          return [
            {
              value: `${item.time1.startTime} - ${item.time1.endTime}`,
            },
            {
              value: `${item.time2.startTime} - ${item.time2.endTime}`,
            },
            {
              value: `${item.time3.startTime} - ${item.time3.endTime}`,
            },
            {
              value: `${item.time4.startTime} - ${item.time4.endTime}`,
            },
          ];
        });
        header = _.flattenDeep(header);
        setTimeSlotHeader(header);
      }
    } catch (error) {
      console.log(
        `[ERROR] => call api /room/checkAvailable error ${
          error.message
        } -- ${JSON.stringify(error)}`
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {}, [data]);

  const getBranchList = (homeStayList) => {
    try {
      const branchListResult = [];
      const notBranch = {
        id: -1,
        name: "",
        numOfHome: 0,
      };
      _.forEach(homeStayList, (homestayInfo) => {
        if (_.isNil(homestayInfo?.branchId)) {
          notBranch.numOfHome += 1;
          return;
        }

        const existHomeStay = _.find(branchListResult, {
          id: homestayInfo?.branchId,
        });
        let numOfHome = 1;
        if (!existHomeStay) {
          branchListResult.push({
            id: homestayInfo?.branchId,
            name: homestayInfo?.branchName,
            color: homestayInfo?.branchColor,
            numOfHome: numOfHome,
          });
          numOfHome++;
        } else {
          existHomeStay.numOfHome += 1;
        }
      });

      if (notBranch.numOfHome > 0) branchListResult.push(notBranch);

      setBranchList(branchListResult);
    } catch (error) {
      console.log(
        `[ERROR] => [AVAILABLE_ALL_ROOM] get branch list error: ${error.message}`
      );
    }
  };

  // const handleScrollHorizontal = (event) => {
  //     console.log('here');
  //     event.preventDefault();

  //     // console.log('here');
  //     const scrollDistance = 50; // Khoảng cách cuộn ngang mong muốn
  //     const currentScroll = tableRef.current.scrollLeft;

  //     const scrollDirection = currentScroll > prevScrollLeft ? 1 : -1; // Xác định hướng cuộn
  //     prevScrollLeft = currentScroll;

  //     tableRef.current.scrollLeft += scrollDirection * scrollDistance;

  //     return false;
  // };

  useEffect(() => {
    setHomeStay(props.data.homeSort);

    getBranchList(props.data.homeSort);

    let start = moment(new Date());
    if (start.isBefore(moment({ hour: 6, minute: 0, second: 0 }))) {
      start = moment().subtract(1, "days");
    }

    fetchData(start, props.data.homeSort);

    return () => {};
  }, [
    props.data.homestay,
    props.data.branchId,
    props.data.roomId,
    props.data.filterLoading,
  ]);

  const handleScroll = (e) => {

    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;

    // Check if the user has scrolled to the bottom
    if (scrollHeight - scrollTop <= clientHeight + 20 && !loading) {

      fetchDataLoadMore();
    }
  };

  const wrapperTableRef = useRef(null);

  // const handleScroll = (event) => {
  //   // Xử lý sự kiện cuộn ở đây
  //   console.log('Scrolled', event.target.scrollTop);
  // };

  useEffect(() => {
    const scrollElement = tableRef.current;
    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading]);

  useEffect(() => {
    const wrapperTable = wrapperTableRef.current;
    if (wrapperTable) {
      wrapperTable.addEventListener("scroll", handleScroll);
    }

    // Cleanup sự kiện khi component bị unmount
    return () => {
      if (wrapperTable) {
        wrapperTable.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    console.log(`filterLoading ${filterLoading}`);
  }, [filterLoading]);

  return (
    <div
      className="infinite-scroll-table"
      style={{ fontFamily: "Cabin" }}
      ref={tableRef}
    >
      {filterLoading ? (
        <div className="loading-spinner">
          {/* <Spinner animation="border" variant="primary" /> */}
        </div>
      ) : (
        <div
          onScroll={handleScroll} // Ensure this function is implemented correctly
          className="wrapper-table"
          ref={wrapperTableRef}
          style={data.length > 7 ? { height: "400px" } : { height: "auto" }}
        >
          <div className="container-table-left">
            <Table className="wrapper-table-left" >
              <thead >
                <tr >
                  <th className="room-highlight" colSpan={2}>
                    <span> Chi Nhánh</span>
                  </th>
                </tr>
                <tr>
                  <th className="room-highlight" colSpan={2}>
                    <span>Tên Phòng</span>
                  </th>
                </tr>
                <tr className="tr-day">
                  <th>Thứ</th>
                  <th>Ngày</th>
                </tr>
              </thead>
              <tbody>
                {data.map((parentItem, index) => (
                  <tr key={index}>
                    <td
                      className="sticky-column"
                      onClick={() => handleChooseFullDay(parentItem)}
                    >
                      {parentItem.date1}
                    </td>
                    <td
                      className="sticky-column"
                      onClick={() => handleChooseFullDay(parentItem)}
                    >
                      {moment(parentItem.date2, "DD-MM-YYYY").format("DD-MM")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="container-table-right">
            <Table className="wrapper-table-right">
              <thead>
                <tr>
                  {getDepartmentListName().map((item, index) => (
                    <th
                      className="th-room-highlight"
                      key={index}
                      colSpan={4}
                      style={{
                        position: "sticky",
                        borderRightWidth: "9px",
                        borderRightStyle: "solid",
                        borderRightColor: "#e0e0e0",
                      }}
                    >
                      <span>{item}</span>
                    </th>
                  ))}
                </tr>
                <tr>
                  {homestay.map((item, index) => (
                    <th
                      key={index}
                      colSpan={4}
                      className="th-room-highlight"
                      style={{
                        position: "sticky",
                        borderRightWidth: "9px",
                        borderRightStyle: "solid",
                        borderRightColor: "",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      <span>{item.name}</span>
                    </th>
                  ))}
                </tr>
                <tr className="freeze-header2" style={{backgroundColor: "#dee2e6"}}>
                  {timeSlotHeader.map((item, index) => (
                    <th
                      key={index}
                      style={{
                        position: "sticky",
                        borderRightWidth:
                          isBorder(index, timeSlotHeader) === true
                            ? "9px"
                            : "0",
                        borderRightStyle: "solid",
                        borderRightColor: "",
                      }}
                    >
                      {item.value}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((parentItem, index) => (
                  <tr key={index}>
                    {parentItem.data.map((item, index) => (
                      <React.Fragment key={index}>
                        <td style={{ minWidth: "75px" }}>
                          {item.time1.isAvailable === false ? (
                            <div className="available-item">''</div>
                          ) : (
                            <div
                              onClick={() =>
                                handleOnClickItem(parentItem.date2, item.time1)
                              }
                              className={`inAvailable-item ${
                                checkClick(parentItem.date2, item?.time1)
                                  ? "click-available-item"
                                  : ""
                              }`}
                            >
                              ''
                            </div>
                          )}
                        </td>
                        <td style={{ minWidth: "70px" }}>
                          {item.time2.isAvailable === false ? (
                            <div className="available-item">''</div>
                          ) : (
                            <div
                              onClick={() =>
                                handleOnClickItem(parentItem.date2, item.time2)
                              }
                              className={`inAvailable-item ${
                                checkClick(parentItem.date2, item?.time2)
                                  ? "click-available-item"
                                  : ""
                              }`}
                            >
                              ''
                            </div>
                          )}
                        </td>
                        <td style={{ minWidth: "70px" }}>
                          {item.time3.isAvailable === false ? (
                            <div className="available-item">''</div>
                          ) : (
                            <div
                              onClick={() =>
                                handleOnClickItem(parentItem.date2, item.time3)
                              }
                              className={`inAvailable-item ${
                                checkClick(parentItem.date2, item?.time3)
                                  ? "click-available-item"
                                  : ""
                              }`}
                            >
                              ''
                            </div>
                          )}
                        </td>
                        <td
                          style={{
                            minWidth: "75px",
                            borderRight: "2px solid #dee2e6",
                            borderRightWidth: "9px",
                            borderRightStyle: "solid",
                            borderRightColor: "",
                          }}
                        >
                          {item.time4.isAvailable === false ? (
                            <div className="available-item">''</div>
                          ) : (
                            <div
                              onClick={() =>
                                handleOnClickItem(parentItem.date2, item.time4)
                              }
                              className={`inAvailable-item ${
                                checkClick(parentItem.date2, item?.time4)
                                  ? "click-available-item"
                                  : ""
                              }`}
                            >
                              ''
                            </div>
                          )}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      )}

      {loading && (
        <div className="loading-spinner">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </div>
  );
};

export default AvailableAllRoom;

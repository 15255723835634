import React, { Fragment, useState, useEffect } from 'react';
import Menu from './menu';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import FixedBottomBar from './fixBottomBar';
import Footer from './footer';

function Contact() {
  const [siteInfo, setSiteInfo] = useState({});
  const navigator = useNavigate();

  const handleButtonNext = () => {

  };

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        let info = await axios.post(
          'https://bayla-be-main.vercel.app/siteInfo'
        );
        info = info?.data;
        if (info?.code === 1000) {
          setSiteInfo({
            facebook: info?.data?.facebook,
            tiktok: info?.data?.tiktok,
            phoneNumber: info?.data?.phoneNumber,
            zalo: info?.data?.zalo,
            images: info?.data?.images,
          });
        }
      } catch (error) {
        console.log(
          `ERROR when call get list homestay ${
            error.message
          } -- ${JSON.stringify(error)}`
        );
        setSiteInfo({
          facebook: 'Link facebook',
          tiktok: 'Link tiktok',
          phoneNumber: 'Số điện thoại',
          zalo: 'Số zalo',
          images: [],
        });
      }
    };
    fetchInfo();
  }, []);

  return (
    <Fragment>
      <Menu />
      <Footer siteInfo={siteInfo} />
      <FixedBottomBar
        totalAmount={0}
        onBack={handleButtonNext}
        
      />
    </Fragment>
  );
}

export default Contact;

import React, { useState, useEffect, Fragment } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome CSS


function BookingSuccess() {


  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    phone: '',
    email: '',
    cccd: '',
    note: ''
  });
  const location = useLocation();
  const navigate = useNavigate();

  const receivedData = location.state ? location.state.data : null;

  useEffect(() => {
    setCustomerInfo(receivedData.customerInfo);
  }, [location, receivedData]);

  useEffect(() => {
  }, [customerInfo]);

  const handleOkClick = () => {
    navigate('/');
   
  };
  const handleResize = () => {
    return window.innerWidth <= 768;
  };

  const handleContinueClick = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      window.location.href = 'intent://#Intent;package=com.google.android.gm;scheme=mailto;end';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = 'googlegmail://';
    } else {
      alert('This feature is only available on mobile devices.');
    }
    // window.location.href = 'mailto:janeharris@gmail.com';
  };
  return (
    <Fragment>
      <div className='spacer-height' style={{ marginTop: '0px', padding: 'unset' }} />
      <section className='room-details-section spad'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='room-booking'>
                <div className="circle-avatar">
                  <i class="fa-solid fa-check" style={{ fontSize: "150px", color: "#fe9574" }}></i>
                </div>
                <h3>Thanh toán thành công!</h3>
                <p style={{ fontSize: "14px" }}>
                  Kiểm tra Gmail <span style={{ fontWeight: "bold", fontSize: "16px" }}>{customerInfo.email}</span> để nhận các thông tin Checkin chi tiết. Bayla Home Danang hy vọng được sớm đón bạn!
                </p>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: '80px',
                  }}
                >
                  <button
                    onClick={handleOkClick} className='button button-next'
                    style={{
                      backgroundColor: '#9768D5',
                      border: 'none',
                      padding: '5px 90px',
                      borderRadius: '50px',
                      width: '70%',
                      fontFamily: 'Gilroy-Regular',
                      fontWeight: 'bold',
                      fontSize: '15px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Về Trang chủ
                  </button>


                </div>
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  marginTop: '20px',
                }}>  <button className='button button-next' onClick={handleContinueClick} style={{
                  backgroundColor: '#9768D5',
                  border: 'none',
                  padding: '5px 90px',
                  borderRadius: '50px',
                  width: '70%',
                  fontFamily: 'Gilroy-Regular',
                  fontWeight: 'bold',
                  fontSize: '15px',
                  textAlign: 'center',
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                }}>
                    Truy cập Gmail
                  </button></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <style jsx>{`
        .room-booking {
          text-align: center; /* Căn giữa toàn bộ nội dung */
        }
        .circle-avatar {
          width: 300px; /* Tăng kích thước của vòng tròn */
          height: 300px; /* Tăng kích thước của vòng tròn */
          border-radius: 50%;
          background: linear-gradient(to top right, #ffdab6, #fff); /* Gradient background */
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto 20px; /* Căn giữa và thêm khoảng cách dưới */
        }
        iframe {
          border-radius: 50%; /* Làm cho iframe có góc bo tròn */
        }
      `}</style>
    </Fragment>
  );
}

export default BookingSuccess;
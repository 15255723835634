import React, { Fragment, useEffect, useState } from 'react';
import Menu from './menu';
import axios from 'axios';
import _, { set } from 'lodash';
import { Await, Link, useNavigate } from 'react-router-dom';
import Footer from './footer';
import CarouselExample from './slide';
import { Col, Row, Spinner, Carousel, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import AvailableAllRoom from './availableAllRoom';
import HomeStaySlide from './homeStaySlide';
import FixedBottomBar from './fixBottomBar';
import moment from 'moment-timezone';
import c from 'clipboard-js';
import SelectInput from './common/selectInput';
import numeral from 'numeral';

function Index() {
  const navigate = useNavigate();

  const [homestay, setHomeStay] = useState([]);
  const [bookingTimeData, setBookingTimeData] = useState({});
  const [bookingHomeStay, setBookingHomeStay] = useState({});
  const [siteInfo, setSiteInfo] = useState({});
  const [errorAPI, setErrorAPI] = useState([]);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [totalPayment, setTotalPayment] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [homeStayOptions, setHomeStayOptions] = useState([]);
  const [roomOptions, setRoomOptions] = useState([]);
  const [branch, setBranch] = useState('');
  const [room, setRoom] = useState('');
  const [branchId, setBranchId] = useState('');
  const [roomId, setRoomId] = useState('');
  const [homeSort, setHomeSort] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);

  const returnDateBookData = (item) => {
    return moment(item.date, 'DD-MM-YYYY').toDate();
  };
  const calTotalPayment = async () => {
    try {
      if (_.isEmpty(bookingTimeData)) {
        setTotalPayment(0);

        console.log('setIsLoading', isLoading);
        return;
      }
      setIsLoading(true);
      let bookingList = _.sortBy(bookingTimeData, returnDateBookData);
      bookingList = _.map(bookingList, (item) => {
        const result = {
          date: item.date,
          slots: [],
        };
        let bookingSlots = _.sortBy(item.bookingSlots, ['order']);
        const slots = _.map(bookingSlots, (slot) => {
          return Number(slot.order) - 1;
        });
        result['slots'] = slots;
        return result;
      });
      const data = {
        roomId: bookingHomeStay.id,
        totalCustomer: 2,
        bookingList,
        isCalAmountOnly: true,
      };
      let response = await axios.post(
        'https://bayla-be-main.vercel.app/booking/v2',
        data
      );
      response = response?.data;
      setIsLoading(false);
      console.log('setIsLoading', false);
      if (response?.code === 1000) {
        setTotalPayment(response?.data?.total);
      }
    } catch (error) {
      console.log('🚀 ~ calTotalPayment ~ error:', error);
      setIsLoading(false);
      console.log('setIsLoading', false);
    }
    setIsLoading(false);
    console.log('setIsLoading', false);
  };

  const chooseBookingTime = async (data) => {
    setBookingTimeData(data);
    if (_.isEmpty(data)) {
      setShowConfirmButton(false);
      setBookingHomeStay({});
    } else {
      setShowConfirmButton(true);
      if (bookingHomeStay.id !== data?.[0]?.roomId) {
        const chooseHomeStay = _.find(homestay, { id: data?.[0]?.roomId });
        setBookingHomeStay(chooseHomeStay);
      }
    }
  };

  const navigateToDetail = () => {
    try {
      navigate(
        // `/detail?name=${bookingHomeStay?.name}&id=${bookingHomeStay?.id}`,
        `/booking-detail?id=${bookingHomeStay?.id}`,
        {
          state: {
            data: { homeStay: bookingHomeStay, bookingTIme: bookingTimeData },
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleNext = () => {
    if (showConfirmButton === false) {
      return navigate('/homestay');
    } else {
      return navigateToDetail();
    }
  };

  const splitAddressName = (address) => {
    const split = address.split(',');
    return `${split[0]},${split[1]}`;
  };

  const fetchHomeStayOptions = () => {
    let uniqAddress = [];
    uniqAddress.push({
      name: 'Tất cả chi nhánh',
      value: '',
    })
     uniqAddress = [...uniqAddress, ...Array.from(
      new Set(homestay.map((address) => address.branchName))
    ).map((item) => {
      return {
        name: splitAddressName(item),
        value: item,
      };
    })];
   
    
    setHomeStayOptions(uniqAddress);

    setRoomOptionsDefault(homestay);
  };

  const setRoomOptionsDefault = (homestay) => {
    if (branch !== '') {
      return;
    }
    let uniqRoom = [];
    uniqRoom.push({
      value: '',
      name: 'Tất cả phòng',
    });
     uniqRoom = [...uniqRoom, ... Array.from(
      new Set(homestay.map((address) => address.name))
    ).map((item) => {
      return {
        name: item,
        value: item,
      };
    })];
    // setRoom('');
    // console.log(`room ${room}`);
    // setRoomId('');

    setRoomOptions(uniqRoom);
  };

  const getRoomByBranch = () => {
    setRoomOptions([]);
    const rooms = [];
    rooms.push({
      name: 'Tất cả phòng',
      value: '',
    });
    const listHomeStay = [];
    homestay.filter((item) => {
      if (item.branchName === branch) {
        listHomeStay.push(item);
        rooms.push({
          value: item.name,
          name: item.name,
        });
      }
    });
    console.log(`rooms ${JSON.stringify(rooms)}`);
    setRoomOptions(rooms);
    setHomeSort(listHomeStay);
  };
  const fetchHomeStay = async () => {
    try {
      let homestayResult = await axios.post(
        'https://bayla-be-main.vercel.app/room/search',
        {},
        { timeout: 60000 }
      );
      homestayResult = homestayResult?.data;
      if (homestayResult?.code === 1000) {
        setHomeStay(homestayResult?.data?.rooms);
      }
    } catch (error) {
      console.log(
        `ERROR when call get list homestay ${error.message} -- ${JSON.stringify(
          error
        )}`
      );
      const errorSearch = [
        {
          api: '/room/search (2)',
          error: error.message,
          data: JSON.stringify(error),
        },
      ];
      setErrorAPI(errorSearch);
    }
  };
  useEffect(() => {}, [isLoading]);

  useEffect(() => {
    if (bookingTimeData) {
      calTotalPayment();
    }

    const fetchHomeStay = async () => {
      try {
        let homestayResult = await axios.post(
          'https://bayla-be-main.vercel.app/room/search',
          {},
          { timeout: 60000 }
        );
        homestayResult = homestayResult?.data;
        if (homestayResult?.code === 1000) {
          setHomeStay(homestayResult?.data?.rooms);
          setHomeSort(homestayResult?.data?.rooms);
        }
      } catch (error) {
        console.log(
          `ERROR when call get list homestay ${
            error.message
          } -- ${JSON.stringify(error)}`
        );
        const errorSearch = [
          {
            api: '/room/search (2)',
            error: error.message,
            data: JSON.stringify(error),
          },
        ];
        setErrorAPI(errorSearch);
      }
    };
    const fetchInfo = async () => {
      try {
        let info = await axios.post(
          'https://bayla-be-main.vercel.app/siteInfo'
        );
        info = info?.data;
        if (info?.code === 1000) {
          setSiteInfo({
            facebook: info?.data?.facebook,
            tiktok: info?.data?.tiktok,
            phoneNumber: info?.data?.phoneNumber,
            zalo: info?.data?.zalo,
            images: info?.data?.images,
          });
        }
      } catch (error) {
        console.log(
          `ERROR when call get list homestay ${
            error.message
          } -- ${JSON.stringify(error)}`
        );
        setSiteInfo({
          facebook: 'Link facebook',
          tiktok: 'Link tiktok',
          phoneNumber: 'Số điện thoại',
          zalo: 'Số zalo',
          images: [],
        });
      }
    };

    fetchHomeStay();

    if (_.isEmpty(homestay)) {
      fetchHomeStay();
    }
    if (_.isEmpty(siteInfo)) {
      fetchInfo();
    }
  }, [branchId, roomId]);

  useEffect(() => {
    if (!_.isEmpty(bookingTimeData)) {
      calTotalPayment();
    } else {
      setTotalPayment(0);
    }
  }, [bookingTimeData]);

  useEffect(() => {
    fetchHomeStayOptions();
  }, [homestay]);

  useEffect(() => {
    setFilterLoading(true);
    if (branch === '') {
      setRoom('');
      setRoomId('');
      setRoomOptionsDefault(homestay);
      setHomeSort(homestay);
      setBranchId('');
      setFilterLoading(false);
      return;
    }
    setRoom('');
    setRoomId('');
    getRoomByBranch();
    const branchId = homestay.find((item) => item.branchName === branch)?.branchId;
    setBranchId(branchId);
    setFilterLoading(false);
  }, [branch]);

  useEffect(() => {
    setFilterLoading(true);
    const roomId = homestay.find((item) => item.name === room)?.id;
    const rooms = [];
  console.log(`branchId ${branchId}`);
    if (room != '') {
      homeSort.filter((item) => {
        if (item.name === room) {
          rooms.push(item);
        }
      });
      setHomeSort(rooms);
      setRoomId(roomId);
    } else if (room != '' && branch != '') {
      getRoomByBranch();
    } else if(room == '' && branch != ''){
      setRoomId('');
      setBranchId(branchId);
      getRoomByBranch();
    }
     else {
      setHomeSort(homestay);
    }
    setFilterLoading(false);
  }, [room]);

  return (
    <Fragment>
      <Menu siteInfo={siteInfo} />
      {/* Hero Section Begin */}
      <section className='hero-section'>
        <Carousel
          className='carousel-home centered-carousel banner-mobile'
          interval={500000} // Shortened interval for demo purposes; adjust as needed
          fade
          indicators
        >
          {_.map(siteInfo.images, (item, index) => (
            <Carousel.Item key={index} className='carousel-home'>
              <Card
                className='center-card'
                style={{
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: '20px',
                  height: '80%', // Set fixed height for consistent display
                }}
              >
                <img
                  className='d-block w-100'
                  src={item}
                  alt=''
                  style={{
                    objectFit: 'contain',
                    // height: '100%', // Full height to fill card
                    borderRadius: '20px',
                  }} // Full height to fill card
                />
              </Card>
            </Carousel.Item>
          ))}
        </Carousel>
      </section>
      {/* <!-- Hero Section End --> */}

      {/* <!-- Home Room Section Begin --> */}
      <section
        className='hp-room-section'
        style={{
          paddingTop: '0px',
        }}
      >
        <div className='container-fluid'>
          <div className='hp-room-items'>
            <div className='row'>
              {/* <li><Link to="/homestay" style={{ color: '#fcead6' }}>Home stay</Link></li> */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <a href='/homestay' style={{ textDecoration: 'none' }}>
                  <button
                    style={{
                      // background: '#B1A9F8', // Màu xanh dương
                      border: 'none',
                      padding: '5px 90px',
                      borderRadius: '50px', // Bo tròn nút
                      color: '#141414',
                      background: 'linear-gradient(to top right, #ffdab6, #fff)',
                      fontFamily: 'Gilroy-Regular',
                      fontWeight: 'bold',
                      fontSize: '18px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      width: '300px'
                    }}
                  >
                    Xem Phòng
                  </button>
                </a>
              </div>

              {/* <HomeStaySlide /> */}
              {!_.isEmpty(homestay) ? (
                <HomeStaySlide data={homestay} />
              ) : (
                <Spinner animation='border' variant='primary' />
              )}
              {!_.isEmpty(errorAPI) ? (
                <p>{JSON.stringify(errorAPI)}</p>
              ) : (
                <p></p>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Home Room Section End --> */}
      <div id='booking'></div>
      <section
        className='hp-room-section'
        style={{ paddingTop: '30px', marginBottom: '50px' }}
      >
        <div className=''>
          <Row style={{ marginBottom: '10px' }}>
            <Col
              xs={{ span: 7, offset: 3 }}
              md={{ span: 7, offset: 3 }}
              sm={{ span: 7, offset: 3 }}
            ></Col>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: '20px',
              }}
            >
              <button
                style={{
                  // background: '#B1A9F8', // Màu xanh dương
                  border: 'none',
                  padding: '5px 90px',
                  borderRadius: '50px', // Bo tròn nút
                  color: '#141414',
                  background: 'linear-gradient(to top right, #ffdab6, #fff)',
                  fontFamily: 'Gilroy-Regular',
                  fontWeight: 'bold',
                  fontSize: '18px',
                  textAlign: 'center',
                  cursor: 'pointer',
                  width: '300px'
                }}
              >
                Lịch Phòng
              </button>
            </div>
            <div className='wrapper-select-homestay'>
              <div>
                <div className='section-select'>
                  <SelectInput
                    options={homeStayOptions}
                    width={'100%'}
                    sendValueToParent={setBranch}
                    // defaultOption='Tất cả chi nhánh'
                    placeholder='Tất cả chi nhánh'
                  />
                </div>
                <div className='section-select'>
                  <SelectInput
                    options={roomOptions}
                    width={'100%'}
                    // defaultOption='Tất cả phòng'
                    sendValueToParent={setRoom}
                    placeholder='Tất cả phòng'
                    roomName={room}
                  />
                </div>
              </div>
            </div>
          </Row>
          {filterLoading ? (
            <Spinner animation='border' variant='primary' />
          ) : !_.isEmpty(homestay) ? (
            <AvailableAllRoom
              data={{ homeSort, branchId, roomId, filterLoading }}
              onChooseTime={chooseBookingTime}
            />
          ) : (
            <Spinner animation='border' variant='primary' />
          )}
          <Row style={{ backgroundColor: 'white', padding: '5px' }}>
            <Col
              xs={12}
              md={6}
              className='d-flex'
              style={{ marginTop: '10px', marginLeft: '10%' }}
            >
              <div style={{ display: 'flex', fontSize: '12px' }}>
                <div
                  style={{
                    margin: '0 10px 0 0',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#fba567',
                      width: '48px',
                      height: '20px',
                      margin: '0 4px 0 0',
                      display: 'flex',
                      borderRadius: '5px',
                    }}
                  ></div>{' '}
                  <span>Đã đặt</span>
                </div>
                <div
                  style={{
                    margin: '0 10px 0 0',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#white',
                      width: '48px',
                      height: '20px',
                      margin: '0 4px 0 0',
                      backgroundColor: '#fff6ee ',
                      borderRadius: '5px',
                    }}
                  ></div>{' '}
                  <span>Còn trống</span>
                </div>
                <div
                  style={{
                    margin: '0 10px 0 0',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#fdc9a3 ',
                      width: '48px',
                      height: '20px',
                      margin: '0 4px 0 0',
                      borderRadius: '5px',
                    }}
                  ></div>{' '}
                  <span>Đang chọn</span>
                </div>
              </div>
            </Col>
            <Col
              xs={6}
              md={{ span: 4, offset: 4 }}
              className='d-flex justify-content-end'
            >
              {/* <button
                disabled={!showConfirmButton}
                className={`confirm-time-booking ${
                  showConfirmButton ? "invalid-btn" : "disabled-btn"
                }`}
                onClick={() => navigateToDetail()}
                style={{ minWidth: "100px", marginTop: "15px" }}
              >
                {" "}
                Xác nhận
              </button> */}
            </Col>
          </Row>
        </div>
      </section>
      <FixedBottomBar
        totalAmount={numeral(totalPayment).format('0,0')}
        onNext={handleNext}
        isLoading={isLoading}
        nameButtonNext='Tiếp Tục'
      />
      <Footer siteInfo={siteInfo} />
    </Fragment>
  );
}

export default Index;
